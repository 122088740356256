import React, {Component} from 'react';
import {translate} from 'react-i18next';
import * as EmailValidator from 'email-validator';
import Tip from '../../modules/tip.js';

import '../../css/support.scss';
import '../../css/support-media.scss';

import {axiosCatch, axiosinstance} from "../../modules/axios";

const XRegExp = require('xregexp');

class View extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      form: {
        name: "",
        email: "",
        subject: "",
        message: ""
      },
      errorForm: {
        name: "",
        email: "",
        subject: "",
        message: ""
      },
      tooltip: {
        name: false,
        email: false,
        subject: false,
        message: false
      },
      done: false,
      loading: false,
      error: {
        state: false,
        text: "",
      }
    }
  }

  handleOnChange = (name, event) => {
    switch (name) {
      case 'subject':
        if (event.target.value.length > 200) {
          return;
        }
        this.setStateInputs(name, event);
        break;
      case 'message':
        if (event.target.value.length > 2000) {
          return;
        }
        this.setStateInputs(name, event);
        break;
      default:
        this.setStateInputs(name, event);
        break;
    }
  };

  setStateInputs(name, event) {
    let form = this.state.form;
    form[name] = event.target.value;
    this.setState({
      form: form
    });
    this.checkErrorForm(name, event.target.value);
  }

  setError = (input, flag) => {
    let setFlag;
    if (flag === true) {
      setFlag = "error";
    } else {
      this.showTooltip(input, false);
      setFlag = "";
    }
    let formErr = this.state.errorForm;
    formErr[input] = setFlag;
    this.setState({
      formError: formErr
    });
  };

  showTooltip = (target, bool) => {
    let tooltip = this.state.tooltip;
    tooltip[target] = bool;
    this.setState({
      tooltip: tooltip
    })
  };

  checkErrorForm = (name, value) => {
    switch (name) {
      case 'name':
        if (!this.validate_form('name', value)) {
          this.setError('name', true)
        } else {
          this.setError('name', false)
        }
        break;
      case 'email':
        if (value.length > 0) {
          if (!EmailValidator.validate(value)) {
            this.setError("email", true)
          } else {
            this.setError("email", false)
          }
        } else {
          this.setError("email", false)
        }
        break;
      case 'subject':
        if (!this.validate_form('subject', value)) {
          this.setError("subject", true)
        } else {
          this.setError("subject", false)
        }
        break;
      case 'message':
        if (!this.validate_form('message', value)) {
          this.setError("message", true)
        } else {
          this.setError("message", false)
        }
        break;
      default:
        break;
    }
  };

  validate_form = (type, value) => {
    let regex;
    switch (type) {
      case "name":
        regex = new XRegExp("^[\\p{L}\\d_ ]{2,50}$");
        return regex.test(value);
      case "subject":
        regex = new XRegExp("^[^<>]{2,200}$");
        return regex.test(value);
      case "message":
        regex = new XRegExp("^[^<>]{2,2000}$");
        return regex.test(value);
      default:
        return null
    }
  };

  submitForm = () => {
    let error = false;
    let form = this.state.form;
    if (!this.validate_form('name', form.name)) {
      this.setError("name", true);
      this.showTooltip("name", true);
      error = true;
    }

    if (!EmailValidator.validate(form.email)) {
      this.setError("email", true);
      this.showTooltip("email", true);
      error = true;
    }

    if (!this.validate_form('subject', form.subject)) {
      this.setError("subject", true);
      this.showTooltip("subject", true);
      error = true;
    }

    if (!this.validate_form('message', form.message)) {
      this.setError("message", true);
      this.showTooltip("message", true);
      error = true;
    }

    if (error) {
      return null
    }

    this.send()

  };

  send = () => {
    this.setState({
      error: {
        state: false, text: ""
      },
      loading: true,
    }, () => {
      axiosinstance({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + "/support",
        data: {
          ...this.state.form
        }
      })
        .then((response) => {
          let ans = response.data;
          if (ans.success === true) {
            this.setState({
              done: true,
              loading: false
            });
          }
        }).catch((args) => {
        this.setState({
          error: {
            state: true,
            text: "internal error"
          },
          loading: false
        });
        //alert('support.error');
        console.log(args);
        axiosCatch(args, this.props);
      });
    });
  };

  render() {
    const t = this.props.t;
    const error = this.state.error;
    return (
      <div>
        <div className="center support-bar bar-padding">
          <div className="max-width width">
            <div className={'left width'}>
              <div className={'flex-row width'}>
                <div className={'div-text'}>
                  <div className={'text'}>
                    <h1 dangerouslySetInnerHTML={{__html: t('support.main.title')}}/><br/>
                    <span dangerouslySetInnerHTML={{__html: t('support.main.text-1')}}/><br/><br/>
                    <span dangerouslySetInnerHTML={{__html: t('support.main.text-2')}}/><br/><br/>
                  </div>
                </div>
                <div className={'div-image'}>
                  <div className={'image'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'support-bar-form bar-padding'}>
          <div className="max-width width">
            <div className={'left width'}>

              <div className={'support-div'}>

                <div className={'flex form'}>

                  <div className={'chat'}>
                    <h3>{t('support.chat.title')}</h3><br/>
                    <div style={{maxWidth: '70%'}} className={'text'}>
                      {t('support.chat.text')}
                    </div>
                  </div>

                  <br/><br/><br/>

                  {!this.state.done ? (

                    <div>
                      <h3>{t('support.form.title')}</h3><br/>

                      <div className={'preform'}>

                        <legend>{t('support.form.name')}:</legend>

                        <Tip text={t('support.tooltip.unacceptable-symbols')}
                             show={this.state.tooltip.name}
                             action={() => this.showTooltip('name', false)}/>

                        <input value={this.state.form.name}

                               className={this.state.errorForm.name}
                               onChange={this.handleOnChange.bind(this, 'name')}/>

                        <legend>{t('support.form.email')}:</legend>

                        <Tip text={t('support.tooltip.not-valid')} show={this.state.tooltip.email}
                             action={() => this.showTooltip('email', false)}/>

                        <input value={this.state.form.email}

                               className={this.state.errorForm.email}
                               onChange={this.handleOnChange.bind(this, 'email')}/>

                      </div>

                      <legend>{t('support.form.subject')}:</legend>

                      <Tip text={t('support.tooltip.unacceptable-symbols')}
                           show={this.state.tooltip.subject}
                           action={() => this.showTooltip('subject', false)}/>

                      <input value={this.state.form.subject}

                             className={this.state.errorForm.subject}
                             onChange={this.handleOnChange.bind(this, 'subject')}/>

                      <legend>{t('support.form.message')} ({2000 - this.state.form.message.length} {t('support.characters left')}):
                      </legend>

                      <Tip text={t('support.tooltip.unacceptable-symbols')}
                           show={this.state.tooltip.message}
                           action={() => this.showTooltip('message', false)}/>

                      <textarea

                        className={this.state.errorForm.message}
                        onChange={this.handleOnChange.bind(this, 'message')}>
                                {this.state.form.message}
                                </textarea>


                      <div className={'form-button'}>
                        {!this.state.loading ? (
                          <button
                            className={'button'}
                            onClick={() => this.submitForm()}>{t('support.form.submit')}</button>
                        ) : (
                          <button
                            className={'button-loading'}/>
                        )}
                      </div>

                      {error.state && (
                        <div className={'message-error'}>
                          {error.text}
                        </div>
                      )}

                    </div>
                  ) : (
                    <div>
                      <h3>{t('support.done-send')}</h3>
                    </div>
                  )}


                </div>

                <div className={'flex address'}>
                  <h3>{t('support.address.title')}</h3>
                  <br/>
                  <b>DIGITAL GOLD LTD</b><br/><br/>
                  Suite 305, Griffith Corporate Centre<br/>
                  Beachmont Kingstown<br/>
                  St Vincent and the Grenadines<br/>
                  {/*Phone: +852 800931931<br/>*/}
                  Mon. - Fri. 09:00 - 17:00 (CET)<br/>
                  <br/><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default translate('locale')(View);


