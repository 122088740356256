import React, {Component} from 'react';
//import {Player} from 'video-react';
import ReactPlayer from 'react-player'

import '../../css/home.scss';
import '../../css/video.css';
import i18n from 'i18next';

class View extends Component {

    render() {

        let url = "https://youtu.be/D3riJkjfby4";

        switch (i18n.language) {
            case "en":
                url = "https://youtu.be/D3riJkjfby4";
                break;
            case "es":
                url = "https://youtu.be/0lmNsUlj2QQ";
                break;
            case "ru":
                url = "https://youtu.be/rVneox-MQ6c";
                break;
            case "tr":
                url = "https://youtu.be/8uBSJyo2UJI";
                break;
            case "fr":
                url = "https://youtu.be/anU6HFhvZL8";
                break;
            case "zh":
                url = "https://youtu.be/fAYHDu0DI98";
                break;
            case "pt":
                url = "https://youtu.be/D4ChMvtk1bQ";
                break;
            case "id":
                url = "https://youtu.be/L89HGbK8nz8";
                break;
            case "pl":
                url = "https://youtu.be/o7gYrP5hTqg";
                break;
            case "ro":
                url = "https://youtu.be/MPONsoI-eQU";
                break;
            case "hr":
                url = "https://youtu.be/4VEo0lvMv64";
                break;
            case "ko":
                url = "https://youtu.be/GySGz-yrZjs";
                break;
            case "de":
                url = "https://youtu.be/C8vNeEPP3yk";
                break;
            case "ja":
                url = "https://youtu.be/YVIdBauMb5U";
                break;
            case "fil":
                url = "https://youtu.be/wMo3m6PjkfY";
                break;
            default:
                url = "https://youtu.be/D3riJkjfby4";
                break;
        }

        //const getLang = localStorage.getItem('i18nextLng');
        return (

            <div className={'center video-block bar-padding'}>
                <div className={'max-width width'}>
                    <div className="div-center width center">

                        <ReactPlayer url={url}
                                     className='react-player'
                                     //onReady
                                     loop
                                     controls={false}
                                     volume={1}
                        />

                        {/*<Player*/}
                        {/*playsInline*/}
                        {/*poster="/video/preview.png"*/}
                        {/*src="/video/fin_001.mp4"*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default View;
