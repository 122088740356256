import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router'
import Loading from "../../modules/loading"
import OrderGreen from "./OrderGreen"
import ListPayment from "./ListPayments/In"
import ListPayout from "./ListPayments/Out"
import '../../css/market.scss';
import '../../css/market-media.scss';
import {axiosCatch, axiosinstance} from "../../modules/axios";
import Copy from '../../modules/copy'

let QRCode = require('qrcode.react');

class View extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      first_login: false,
      refresh: {
        get_address: 1,
        get_data: 10
      },
      loading: true,
      loading_success: false,
      loading_error: false,
      order: {
        amount: 0,
        rate: 0,
        status: 0,
        currency_from: "",
        currency_to: "",
        payments: [],
        payout: [],
        token: "",
        expire: 0
      },
      error: ""
    }
  }

  componentDidMount() {
    this.load();
  };

  updateRefresh(item) {
    let refresh = this.state.refresh;
    refresh[item]++;
    this.setState({
      refresh: refresh
    });
  }

  load = () => {

    this.setState({}, () => {
      console.log('load');
      axiosinstance({
        method: 'get',
        url: process.env.REACT_APP_BACKEND_EXCHANGE_URL + "/exchange.json?token=" + this.props.order_id,
        params: {}
      })
        .then((response) => {
          let ans = response.data;
          this.setState({
            first_login: true,
            order: ans,
            loading_success: true
          }, () => {
            this.updateRefresh("get_address");
            if (ans.address === "") {
              setTimeout(() => {
                this.load();
              }, 1000 * this.state.refresh.get_address
              );
            } else {
              //if (ans.status < 5) {
              setTimeout(() => {
                this.load();
              }, this.state.refresh.get_data * 1000
              );
              //}
            }
          });
        }).catch((args) => {

        let error = "";
        if (args.response !== undefined) {
          error = args.response.data.message
        } else {
          error = "Connect timeout"
        }
        //alert("!");
        if (!this.state.first_login) {
          this.setState({
            loading_error: true,
            loading_succes: false,
            error: error
          }, () => {
            setTimeout(() => {
                this.load();
              }, 3000
            );
          });
        }
        axiosCatch(args, this.props);
      });

    });
  };

  tofixed = (val, num) => {
    return val.toFixed(num).replace(/\.?0*$/, '');
  };

  strPadLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };

  new = () => {
    window.open('/market');
  };

  timeToSec = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    return this.strPadLeft(minutes, '0', 2) + 'm ' + this.strPadLeft(seconds, '0', 2) + 's';
  };

  render() {

    const {
      t,
    } = this.props;

    let order = this.state.order;

    if (this.state.loading_error) {
      return (

        <div>
          <div className={'div-loading-preloader'}>
            <div className={'div-loading flex-center-vertical'}>
              <div className={'message-error'}>{this.state.error}</div>
            </div>
          </div>
        </div>

      )
    } else {
      if (this.state.loading_success === true) {

        let wallet = "";

        switch (order.currency_from) {
          case 'btc':
            wallet = "bitcoin";
            break;
          case 'eth':
            wallet = "ethereum";
            break;
          case 'gold':
            wallet = "gold";
            break;
          default:
            wallet = "n/a";
            break;
        }

        const qrcode = wallet + ":" + order.payment_address + "?amount=" + order.amount;

        const {i18n} = this.props;

        return (
          <div className={''}>
            <OrderGreen order={this.state.order}/>
            <div className={'max-width market-div-additional z-index1 width'}>
              <div className={'flex-row width flex-wrap'}>

                <div className={'order-bar'}>
                  <div className={'max-width width'}>
                    <h3>{t('market-order.form.order')} : #{order.id} </h3>

                    <div className={'order-div-address'}>

                      <div className={'i10 flex left'}>
                                                        <span className={'width'}>
                                                           <span
                                                             className={'legend bold'}>{t('market-order.form.payment_address', {
                                                             amount: order.amount,
                                                             currency: order.currency_from.toUpperCase()
                                                           })}</span>

                                                          {order.payment_address === "" ? (
                                                            <span
                                                              className={'market-order-input flex-center-vertical'}>
                                                                        <Loading
                                                                          color={'dark'} size={'medium'}/>
                                                                             </span>
                                                          ) : (
                                                            <span className={'market-order-input inputflexer'}>
                                                                      <span
                                                                        className={'flex'}>{order.payment_address}</span> <Copy
                                                              value={order.payment_address}/>
                                                                    <a href={qrcode}><div
                                                                      className={'wallet-link'}/></a>
                                                                             </span>


                                                          )}

                                                          <div className={'flex-row width'}>
                                                                    <span className={'qrdiv'}>
                                                                          <span
                                                                            className={'qrdiv2'}>
                                                                        {order.payment_address !== "" ? (
                                                                          <QRCode includeMargin={2}
                                                                                  value={qrcode}
                                                                                  size="100%"
                                                                                  renderAs={'svg'}
                                                                                  level={"M"}
                                                                          />
                                                                        ) : (
                                                                          <div
                                                                            className={'flex-center-vertical preloader-loading-qr'}>
                                                                            <Loading color={'dark'}/>
                                                                          </div>

                                                                        )}

                                                                    </span>
                                                                            </span>
                                                                    <span className={'order-status-area'}>
                                                                        {t('market-order.form.status')}: {t('market-order.form.waiting-for-payment')}<br/><br/>
                                                                      {order.expire > 0 ? (
                                                                        <span>{t('market-order.form.rate-fixed-time')}: {this.timeToSec(order.expire)}</span>
                                                                      ) : (
                                                                        <span
                                                                          className={'expire-div'}>{t('market-order.form.expire-text')}</span>
                                                                      )}
                                                                    </span>
                                                            </div>

                                                            <br/><br/><br/>
                                                        </span>
                      </div>


                      <div className={'flex left'}>
                                            <span className={'width'}>
                                                 {order.expire > 0 ? (
                                                   <span className={'legend'}>{t('market-order.form.payout_address', {
                                                     amount: order.receive,
                                                     currency: order.currency_to.toUpperCase()
                                                   })}</span>
                                                 ) : (
                                                   <span className={'legend'}>{t('market-order.form.payout_address', {
                                                     amount: "",
                                                     currency: order.currency_to.toUpperCase()
                                                   })}</span>
                                                 )}
                                              <span className={'market-order-input'}>
                                                            <span>{order.payout_address}</span>
                                                    </span>
                                                </span>
                      </div>

                    </div>

                  </div>
                  <br/><br/>

                  <div className={'flex-row width flex-wrap'}>
                    <ListPayment order={order}/>
                    <ListPayout order={order}/>
                  </div>

                  <br/><br/>

                  <div className={'form-button left width'}>
                    <button className={'button-back speed-animate'}
                            onClick={() => this.props.history.push("/" + i18n.language + "/market")}>{t('market-order.form.cancel')}</button>

                    <button className={'button-exchange-v2 speed-animate'}
                            onClick={() => this.new()}>{t('market-order.form.new')}</button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className={'div-loading-preloader'}>
              <div className={'div-loading flex-center-vertical'}>
                <Loading color={'dark'}
                         size={'big'}/>
              </div>
            </div>
          </div>
        )
      }

    }

  }
}

export default withRouter(translate('locale')(View));
