//import {NotifyError} from './notify.js';
let axios = require('axios');

export const axiosinstance = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 10000,
    transformRequest: [(params) => {
        let body = '';

        if (params === undefined) {
            return body;
        }

        Object.keys(params).forEach((name, index) => {
            let value = params[name];

            if (
                (!value && typeof value !== 'boolean' && typeof value !== 'number')
                || (Array.isArray(value) && value.length === 0)
            ) {
                return;
            }

            if (index > 0) {
                body += '&';
            }

            if (Array.isArray(value)) {
                value = value.join(',');
            }

            return body += `${name}=${value}`;
        });

        return body;
    }],
    mode: 'no-cors',
    crossdomain: true,
    withCredentials: false,
});

export function axiosCatch(args, props) {
    if (!args.response) {
    } else {
        let ans = args.response.data;
        if (ans.success === false) {

        } else {

        }
    }
}
