import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Copy from '../../modules/copy'
import conf from '../../config.json'

import '../../css/dev.scss';

class View extends Component {
    render() {

        const t = this.props.t;
        //const address = '0x0d16450d347c12c086d6c94c76c5aaac35ea07e0';
        const address = '0x851E13B3cdCaEc6FB3ae446dAd82E112ABaA827c';
        return (
            <div>
                <div className="center dev-bar bar-padding">
                    <div className="max-width width">
                        <div className={'left width'}>
                            <h1>{t('dev.title')}</h1>
                            <div className={'text'}>
                                {t('dev.text-1')}<br/>
                                {t('dev.text-2')}<br/><br/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center dev-bar2 bar-padding">
                    <div className="max-width width">
                        <div className={'left width'}>
                            <h3>{t('dev.contract.title')}</h3>
                            <div className={'text'}>
                                <table cellPadding={'3'}>
                                    <tbody>
                                    <tr>
                                        <td>{t('dev.contract.symbol')}:</td>
                                        <td><b>GOLD</b></td>
                                    </tr>
                                    <tr>
                                        <td>{t('dev.contract.contract_address')}:</td>
                                        <td><b className={'flex-row'}>

                                                <span className={''}><span
                                                    className={' flex-center-vertical'}>{address}</span></span>
                                            <span className={' flex-center-vertical'}
                                                  style={{left: '5px', top: '-3px', position: 'relative'}}> <Copy
                                                value={address}
                                                size={'small'}/></span>

                                        </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('dev.contract.decimals')}:</td>
                                        <td><b>3</b></td>
                                    </tr>
                                    <tr>
                                        <td>{t('dev.contract.gas_limit')}:</td>
                                        <td><b>250000</b></td>
                                    </tr>
                                    <tr>
                                        <td>{t('dev.contract.gas_consumption')}:</td>
                                        <td><b>71000 - 91000</b></td>
                                    </tr>
                                    <tr>
                                        <td>{t('dev.contract.minimum_amount')}:</td>
                                        <td><b>0.001 GOLD</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <br/>
                            <h3>{t('dev.overview.title')}</h3>
                            <div className={'text'}>
                                {t('dev.overview.text')}
                            </div>

                            <br/>
                            <h3>{t('dev.transfer-fees.title')}</h3>
                            <div className={'text'}>
                                {t('dev.transfer-fees.text')}
                            </div>

                            <br/>
                            <h3>{t('dev.annual-fees.title')}</h3>
                            <div className={'text'}>
                                {t('dev.annual-fees.text', { 'annualFee': conf.annualFee })}
                            </div>

                            <br/>
                            <h3>{t('dev.exchange.title')}</h3>
                            <div className={'text'}>
                                {t('dev.exchange.text1')}&nbsp;
                                {t('dev.exchange.text2')}
                            </div>

                            <br/>
                            <h3>{t('dev.standards.title')}</h3>

                            {t('dev.standards.text-2')}<br/><br/>
                            {t('dev.standards.text-3')}<br/><br/>
                            <div className={'text'}>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-4')}}/><br/><br/>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-5')}}/><br/><br/>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-6')}}/><br/><br/>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-7')}}/><br/><br/>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-8')}}/><br/><br/>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-9')}}/><br/>
                            </div>
                            <br/>
                            {t('dev.standards.text-10')}<br/><br/>
                            <div className={'text'}>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-11')}}/><br/><br/>
                                <span dangerouslySetInnerHTML={{__html: t('dev.standards.text-12')}}/><br/>
                            </div>

                            <br/>
                            <h3>{t('dev.audit.title')}</h3>
                            <div className={'text'} dangerouslySetInnerHTML={{__html: t('dev.audit.text-3')}}/>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);
