import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {translate} from 'react-i18next';

class View extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            order: this.props.order,
        }
    }

    tofixed = (val, num) => {
        return val.toFixed(num).replace(/\.?0*$/, '');
    };

    render() {

        const order = this.state.order;

        const {
            t,
        } = this.props;

        return (

            <div className={'max-width market-div z-index2 width'}>
                <div className={'flex-row width resolution-flex'}>
                    <div className={'left market-item'}>
                        <div className={'legend'}>{t('market.form.you_send')}</div>

                        <div className={'input flex-row'}>

                            <input value={order.amount}
                                   type="text"
                                   size={12}
                            />

                            <div className={'market-select ' + order.currency_from}
                                 onClick={this.showSend}>

                            </div>

                        </div>

                        <div className={'rate up'}>
                            <span>1 {order.currency_from} = {order.rate} {order.currency_to}</span>
                        </div>

                    </div>
                    <div className={'market-item-change'}>
                        <img src={'/img/change2.svg'} className={'img-change'} alt={''}/>
                    </div>
                    <div className={' left market-item item-margintop'}>
                        <div className={'legend'}>{t('market.form.you_get')}</div>
                        <div className={'input flex-row'}>

                            <input value={order.receive}
                                   size={12}
                                   type="text"
                            />

                            <div className={'market-select ' + order.currency_to}>

                            </div>
                        </div>

                        <div className={'rate up'}>
                            <span>1 {order.currency_to} = {this.tofixed(1 / order.rate, 8)} {order.currency_from}</span>
                        </div>

                    </div>

                </div>

            </div>

        )
    }
}

export default withRouter(translate('locale')(View));
