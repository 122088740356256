import AppPublic from "./containers/AppPublic";
import Home from "./views/Home/Home";
import Features from "./views/Features/Features";
import Solutions from "./views/Solutions/Solutions";
import MarketPlace from "./views/MarketPlace/MarketPlace";
import News from "./views/News/News";
import NewsItem from "./views/News/Item";
import About from "./views/About/About";
import Terms from "./views/Terms/Terms";
import Fee from "./views/Fee/Fee";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import Audits from "./views/Audits/Audits";
import Support from "./views/Support/Support";
import Wallets from "./views/Wallets/Wallets";
import Error404 from "./views/404/404";
import Ver from "./views/Ver/Ver";

import Developers from "./views/Developers/Developers";

//import i18n from './i18n';

const appRoutes = [
    {path: "/404", link: "/404", name: "404", container: AppPublic, comp: Error404, title: "404", menuhide: true, redirect: false},

    {path: "/home", to: "/home", container: AppPublic, redirect: true},
    {path: "/features", to: "/features", container: AppPublic, redirect: true},
    {path: "/solutions", to:  "/solutions", container: AppPublic, redirect: true},
    {path: "/market/:id", to:  "/market/:id", container: AppPublic, redirect: true},
    {path: "/market", to:  "/market", container: AppPublic, redirect: true},
    {path: "/news/:id", to:  "/news/:id", container: AppPublic, redirect: true},
    {path: "/news", to:  "/news", container: AppPublic, redirect: true},
    {path: "/about", to:  "/about", container: AppPublic, redirect: true},
    {path: "/fee", to:  "/fee", container: AppPublic, redirect: true},
    {path: "/terms", to:  "/terms", container: AppPublic, redirect: true},
    {path: "/privacy-policy", to:  "/privacy-policy", container: AppPublic, redirect: true},
    {path: "/audits", to:  "/audits", container: AppPublic, redirect: true},
    {path: "/support", to:  "/support", container: AppPublic, redirect: true},
    {path: "/wallets", to:  "/wallets", container: AppPublic, redirect: true},
    {path: "/developers", to:  "/developers", container: AppPublic, redirect: true},
    {path: "/ver", to:  "/ver", container: AppPublic, redirect: true},


    // pages
    {path: "/:lang/home", link: "/home",  name: "home", container: AppPublic, comp: Home, title: "Home"},
    {path: "/:lang/features", link: "/features", name: "features", container: AppPublic, comp: Features, title: "Features"},
    {path: "/:lang/solutions", link: "/solutions", name: "solutions", container: AppPublic, comp: Solutions, title: "Solutions"},
    {path: "/:lang/market/:id", link: "/market", container: AppPublic, comp: MarketPlace, menuhide: true, title: "Marketplace"},
    {path: "/:lang/market", link: "/market", name: "market", container: AppPublic, comp: MarketPlace, menuhide: false, title: "Marketplace"},
    {path: "/:lang/news/:id", link: "/news", name: "news", container: AppPublic, comp: NewsItem, menuhide: true, title: "News"},
    {path: "/:lang/news", link: "/news", name: "news", container: AppPublic, comp: News, title: "News"},
    {path: "/:lang/about", link: "/about", name: "about", container: AppPublic, comp: About, menuhide: true, title: "About"},
    {path: "/:lang/fee", link: "/fee", name: "fee", container: AppPublic, comp: Fee, menuhide: true, title: "Fee"},
    {path: "/:lang/terms", link: "/terms", name: "terms", container: AppPublic, comp: Terms, menuhide: true, title: "Terms and Conditions"},
    {path: "/:lang/privacy-policy", link: "/privacy-policy", name: "privacy_policy", container: AppPublic, comp: PrivacyPolicy, menuhide: true, title: "Privacy policy"},
    {path: "/:lang/audits", link: "/audits", name: "audits", container: AppPublic, comp: Audits, menuhide: true, title: "Audits"},
    {path: "/:lang/support", link: "/support", name: "support", container: AppPublic, comp: Support, menuhide: true, title: "Customer service"},
    {path: "/:lang/wallets", link: "/wallets", name: "wallets", container: AppPublic, comp: Wallets, menuhide: true, title: "Compatible wallets"},
    {path: "/:lang/developers", link: "/developers", name: "developers", container: AppPublic, comp: Developers, menuhide: true, title: "For Developers"},
    {path: "/:lang/ver", link: "/ver", name: "ver", container: AppPublic, comp: Ver, menuhide: true, title: "For Developers"},


    //redirect
    {path: "/", to: "/home", name: "main", container: AppPublic, menuhide: true, redirect: true},

    //404
    //{path: "/", name: "", container: AppPublic, comp: Error404, title: "", menuhide: true},

];

export default appRoutes
