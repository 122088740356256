import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Graph from './Graph.js';

class View extends Component {

    constructor(props, context) {

        super(props, context);

        this.state = {
            rates: {
                spot: null,
                buy: {
                    btc: 0,
                    eth: 0
                },
                sell: {
                    btc: 0,
                    eth: 0
                }
            }
        }
    }

    // componentDidMount() {
    //     if (this.props.id !== undefined) {
    //         if (this.uuidValidate(this.props.id)) {
    //             const node = this.refs.trackerRef;
    //             node && node.scrollIntoView({block: "start", behavior: 'smooth'})
    //         }
    //     }
    // }

    // componentDidUpdate() {
    //     const node = this.refs.trackerRef;
    //     node && node.scrollIntoView({block: "start", behavior: 'smooth'})
    // }

    componentWillReceiveProps(newProps) {
        if (newProps.rate !== this.props.rate) {

            let mapRate = newProps.rate;

            let btc_gold = 0;
            let eth_gold = 0;
            let gold_btc = 0;
            let gold_eth = 0;

            if (mapRate.get("btc_gold") !== undefined) {
                btc_gold = this.tofixed(1 / mapRate.get("btc_gold").rate, 8)
            }

            if (mapRate.get("eth_gold") !== undefined) {
                eth_gold = this.tofixed(1 / mapRate.get("eth_gold").rate, 8)
            }

            if (mapRate.get("gold_btc") !== undefined) {
                gold_btc = this.tofixed(mapRate.get("gold_btc").rate, 8)
            }

            if (mapRate.get("gold_eth") !== undefined) {
                gold_eth = this.tofixed(mapRate.get("gold_eth").rate, 8)
            }

            let spot;

            if (newProps.spot !== undefined) {
                spot = {
                    btc: newProps.spot.btc,
                    eth: newProps.spot.eth
                };
            } else {
                spot = null
            }

            this.setState({

                rates: {
                    buy: {
                        btc: btc_gold,
                        eth: eth_gold,
                    },
                    sell: {
                        btc: gold_btc,
                        eth: gold_eth,
                    },
                    spot: spot
                }
            });

        }

    }

    tofixed = (val, num) => {
        return val.toFixed(num).replace(/\.?0*$/, '');
    };

    // uuidValidate(uuid) {
    //     return uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i) !== null;
    // }

    render() {

        const rates = this.state.rates;

        const {
            t,
        } = this.props;

        return (
            <div className={'market-bar bar-padding-without-bottom'} >
                <div className={'max-width width'}>
                    <div className={'left width'}>
                        <h1>{t('market.main.title')}</h1>
                        {t('market.main.text-1')}

                        <div className={'info-graph-area'}>
                            <div className={'flex flex-column graph-price'}>
                                <h4>{t('market.graph.price_inf')}</h4>
                                <table width="100%" cellPadding={10}>
                                    <tbody>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th align="right" width="100">BTC</th>
                                        <th align="right" width="100">ETH</th>
                                    </tr>
                                    {rates.spot !== null && (
                                        <tr>
                                            <td>{t('market.graph.spot')}</td>
                                            <td align="right">{rates.spot.btc}</td>
                                            <td align="right">{rates.spot.eth}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>{t('market.graph.buy_price')}</td>
                                        <td align="right">{rates.buy.btc}</td>
                                        <td align="right">{rates.buy.eth}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('market.graph.sell_price')}</td>
                                        <td align="right">{rates.sell.btc}</td>
                                        <td align="right">{rates.sell.eth}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} className={'border-zero'}><span
                                            className={'sn'}>{t('market.graph.sn')}</span></td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            <Graph class={'flex'}/>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);