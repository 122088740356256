import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router'

class Footer extends Component {
  render() {
    const {i18n, t} = this.props;

    return (
      <footer className="bar-padding">
        <div className={'max-width width'}>
          <div className={'flex-row bottom-around'}>

            <div className={'footer-div-resources'}>
              <h3>{t('footer.title-resources')}</h3>
              <hr/>
              <div className={'footer-menu flex-row'}>
                <div className={'menu-item-first'}>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/features")}>{t('footer.menu.features')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/developers")}>{t('footer.menu.developers')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/wallets")}>{t('footer.menu.compatible-wallets')}</span><br/>
                </div>
                <div className={''}>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/solutions")}>{t('footer.menu.solutions')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/market")}>{t('footer.menu.marketplace')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/support")}>{t('footer.menu.customer-support')}</span><br/>
                </div>
              </div>
            </div>

            <div className={'footer-div-about'}>
              <h3>{t('footer.title-about')}</h3>
              <hr/>
              <div className={'footer-menu flex-row'}>
                <div className={'menu-item-first'}>
                                    <span
                                      onClick={() => this.props.history.push("/" + i18n.language + "/about")}>{t('footer.menu.about-us')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/fee")}>{t('footer.menu.fee-schedule')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/news")}>{t('footer.menu.news')}</span><br/>
                </div>
                <div>
                                    <span
                                      onClick={() => this.props.history.push("/" + i18n.language + "/audits")}>{t('footer.menu.audits')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/terms")}>{t('footer.menu.terms-of-use')}</span><br/>
                  <span
                    onClick={() => this.props.history.push("/" + i18n.language + "/privacy-policy")}>{t('footer.menu.privacy-policy')}</span><br/>
                </div>
              </div>
            </div>

            <div className={'footer-div-copyright'}>
              <div className={'flex-row width'}>
                <div className={'logo pointer'}
                     onClick={() => this.props.history.push("/" + i18n.language + "/")}/>
              </div>
              <div className={'footer-logo-spacer'}/>
              {/*<font>{t('footer.question-give-us-a-call')}: +852 800931931</font><br/>*/}
              <br/>
              {/*<font className="copyright">{t('footer.copyright')}</font>*/}
              <font className="copyright">Copyright @ 2019 - {new Date().getFullYear()} Digital Gold, All right reserved.</font>

              <div className={'socials-div flex-row'}>

                <a target={'_new'} href={'https://t.me/digitalgoldcoin'}>
                  <div className={'s-telegram'}/>
                </a>
                <a target={'_new'} href={'https://twitter.com/gold_erc20'}>
                  <div className={'s-twitter'}/>
                </a>
                <a target={'_new'} href={'https://steemit.com/@digitalgoldcoin'}>
                  <div className={'s-medium'}/>
                </a>
                <a target={'_new'} href={'https://www.youtube.com/channel/UCUo-D88vDTvntg2QhxDqBGQ'}>
                  <div className={'s-youtube'}/>
                </a>
                {/*<a target={'_new'} href={'https://facebook.com/golderc20'}>*/}
                {/*<div className={'s-facebook'}/>*/}
                {/*</a>*/}
                <a target={'_new'} href={'https://www.reddit.com/r/golderc20/'}>
                  <div className={'s-reddit'}/>
                </a>
                <a target={'_new'} href={'https://bitcointalk.org/index.php?topic=5161544.0'}>
                  <div className={'s-bitcointalk'}/>
                </a>
              </div>
            </div>

          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(translate('locale')(Footer));

