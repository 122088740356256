import React, {Component} from 'react';
import {translate} from 'react-i18next';

import '../../css/wallets.scss';

class View extends Component {
    render() {
        const t = this.props.t;
        return (
            <div>
                <div className="center wallets-bar bar-padding">
                    <div className="max-width width">
                        <div className={'left'}>
                            <div className={'flex-row width'}>
                                <div className={'div-text'}>
                                    <h1 dangerouslySetInnerHTML={{__html: t('wallets.main.title')}}/><br/>
                                    <div className={'text'}>
                                        <span dangerouslySetInnerHTML={{__html: t('wallets.main2.text-1')}}/><br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center wallets-bar2 bar-padding">
                    <div className="max-width center width">
                        <div className={'width'}>
                            <div className={'text'}>
                                <h3 dangerouslySetInnerHTML={{__html: t('wallets.list.title')}}/><br/>
                            </div>

                            <div className={'wallets'}>

                                <div className={'wallets2'}>
                                    <div className={'img-wallets mywallets'}/>
                                    <div className={'text'}>
                                        <span className={'name'}>{t('wallets.list.wallet-2.name')}</span> <span className={'small'}>{t('wallets.list.wallet-2.type')}</span><br/>
                                        <a href={t('wallets.list.wallet-2.url')} target={'_new'}>{t('wallets.list.wallet-2.url')}</a>

                                        <br/><br/>
                                        <font dangerouslySetInnerHTML={{__html: t('wallets.list.wallet-2.comment')}}/>
                                    </div>
                                </div>

                                <div className={'wallets2'}>
                                    <div className={'img-wallets metamask'}/>
                                    <div className={'text'}>
                                        <span className={'name'}>{t('wallets.list.wallet-1.name')}</span>  <span className={'small'}>{t('wallets.list.wallet-1.type')}</span><br/>
                                        <a href={t('wallets.list.wallet-1.url')} target={'_new'}>{t('wallets.list.wallet-1.url')}</a>

                                        <br/><br/>
                                        <font dangerouslySetInnerHTML={{__html: t('wallets.list.wallet-1.comment')}}/>
                                    </div>
                                </div>

                                <div className={'wallets2'}>
                                    <div className={'img-wallets guarda'}/>
                                    <div className={'text'}>
                                        <span className={'name'}>{t('wallets.list.wallet-3.name')}</span>
                                        <span className={'small'}>{t('wallets.list.wallet-3.type2')}</span>
                                        <span className={'small'}>{t('wallets.list.wallet-3.type3')}</span>
                                        <span className={'small'}>{t('wallets.list.wallet-3.type3')}</span>
                                        <br/>
                                        <a href={t('wallets.list.wallet-3.url')} target={'_new'}>{t('wallets.list.wallet-3.url')}</a>

                                        <br/><br/>
                                        <font dangerouslySetInnerHTML={{__html: t('wallets.list.wallet-3.comment')}}/>
                                    </div>
                                </div>

                                <div className={'wallets2'}>
                                    <div src className={'img-wallets nano'}/>
                                    <div className={'text'}>
                                        <span className={'name'}>{t('wallets.list.wallet-4.name')}</span> <span className={'small'}>{t('wallets.list.wallet-4.type')}</span><br/>
                                        <a href={t('wallets.list.wallet-4.url')} target={'_new'}>{t('wallets.list.wallet-4.url')}</a>

                                        <br/><br/>
                                        <font dangerouslySetInnerHTML={{__html: t('wallets.list.wallet-4.comment')}}/>
                                    </div>
                                </div>

                                <div className={'wallets2'}>
                                    <div className={'img-wallets trezor'}/>
                                    <div className={'text'}>
                                        <span className={'name'}>{t('wallets.list.wallet-5.name')}</span> <span className={'small'}>{t('wallets.list.wallet-5.type')}</span><br/>
                                        <a href={t('wallets.list.wallet-5.url')} target={'_new'}>{t('wallets.list.wallet-5.url')}</a>

                                        <br/><br/>
                                        <font dangerouslySetInnerHTML={{__html: t('wallets.list.wallet-5.comment')}}/>
                                    </div>
                                </div>

                            </div>

                             <br/><br/>

                            <div className={'additional'}>
                                <span dangerouslySetInnerHTML={{__html: t('wallets.main.text-1')}}/><br/><br/>
                                <span dangerouslySetInnerHTML={{__html: t('wallets.main.text-2')}}/><br/>
                            </div>

                        </div>
                    </div>
                </div>

                {/*<div className="center wallets-bar3 bar-padding">*/}
                {/*<div className="max-width width">*/}
                {/*<div className={'left width'}>*/}
                {/*<div className={'width'}>*/}
                {/*<div className={'text'}>*/}
                {/*<h1 dangerouslySetInnerHTML={{__html: t('wallets.nolist.title')}}/>*/}
                {/*<span dangerouslySetInnerHTML={{__html: t('wallets.nolist.text')}}/><br/><br/>*/}
                {/*</div>*/}
                {/*<div className={'wallets width '}>*/}
                {/*<div className={'img-wallets'}/>*/}
                {/*<div className={'img-wallets'}/>*/}
                {/*<div className={'img-wallets'}/>*/}
                {/*<div className={'img-wallets'}/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}

            </div>
        )
    }
}

export default translate('locale')(View);

