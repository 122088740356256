import React, {Component} from 'react';
import {translate} from 'react-i18next';
import NumberFormat from 'react-number-format';

import '../../css/audits.scss';
import '../../css/audits-media.scss';

import {axiosCatch, axiosinstance} from "../../modules/axios";

class View extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      details: {
        assets: 0,
        circulation: 0
      }
    }
  }

  componentDidMount() {
    this.load();
  };

  load = () => {
    axiosinstance({
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + "/info",
      params: {}
    })
      .then((response) => {
        let ans = response.data;
        if (ans.success === true) {
          if (ans.data.details != null) {
            this.setState({
              details: ans.data.details,
            });
            console.log(JSON.stringify(ans.data.details))
          }
          //alert(ans.news);
        }
        //this.props.onHide();

      }).catch((args) => {
      axiosCatch(args, this.props);
    });
  };

  tofixed = (val, num) => {
    return val.toFixed(num).replace(/\.?0*$/, '');
  };

  render() {

    console.log(this.state.details);

    const details = this.state.details;

    const t = this.props.t;
    return (
      <div>

        <div className="center audits-bar2 bar-padding">
          <div className="max-width width">
            <div className={'left width'}>
              <div className={'text'}>
                <h1 dangerouslySetInnerHTML={{__html: t('audits.contract.title')}}/><br/>
                <div className={'flex-row audits-blocks'}>
                  <a target={'_new'} href={'/Statement-of-Security-Audit-for-Gold.Storage.pdf'}>
                    <div className={'audit-contract-preview chainsecurity'}/>
                  </a>
                  <div className={'text'}>
                    <span dangerouslySetInnerHTML={{__html: t('audits.contract.text1')}}/><br/><br/>
                    <br/>
                  </div>
                </div>
                <div className={'flex-row audits-blocks'}>
                  <a target={'_new'} href={'/AuditReport_DigitalGold.pdf'}>
                    <div className={'audit-contract-preview anchain'}/>
                  </a>
                  <div className={'text'}>
                    <span dangerouslySetInnerHTML={{__html: t('audits.contract.text2')}}/><br/><br/>
                    <br/>
                  </div>
                </div>

                <h1 dangerouslySetInnerHTML={{__html: t('audits.main.title')}}/><br/>
                <div className={'text'}>
                  <span dangerouslySetInnerHTML={{__html: t('audits.main.text-1')}}/><br/><br/>
                  <span dangerouslySetInnerHTML={{__html: t('audits.main.text-3')}}/><br/><br/>

                </div>
                <br/>
                <h3 dangerouslySetInnerHTML={{__html: t('audits.block.title')}}/>
                <div className={'audit'}>
                  <a href={t('audits.block.url')} target={'_new'}
                     dangerouslySetInnerHTML={{__html: t('audits.block.url')}}/><br/><br/>
                  <span dangerouslySetInnerHTML={{__html: t('audits.block.text')}}/><br/><br/>

                  <a href={'/vault.pdf'} className={'pdf-url'}
                     target={'_new'}>{t('audits.main.vault')}</a>
                </div>

                <br/><br/>
                <div className={'width flex-center-vertical'}>

                  {details.circulation !== 0 && (
                    <div className={'width stat flex-row '}>
                      <div className={'flex flex-column flex-center-vertical'}>
                        <h4>{t('audits.stat.block-1-title')}</h4>
                        <span><NumberFormat value={details.circulation} displayType={'text'}
                                            thousandSeparator={true}/> {t('audits.stat.tokens')}</span>
                        <span><NumberFormat value={details.circulation * details.last_price}
                                            displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}/></span>
                      </div>
                      <div className={'flex flex-column flex-center-vertical'}>
                        <h4>{t('audits.stat.block-2-title')}</h4>
                        <span><NumberFormat value={details.assets} displayType={'text'}
                                            thousandSeparator={true}/> {t('audits.stat.grams')}</span>
                        <span><NumberFormat value={details.assets * details.last_price}
                                            displayType={'text'}
                                            thousandSeparator={true} prefix={'$'}/></span>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )

  }
}

export default translate('locale')(View);

