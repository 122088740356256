import React, {Component} from 'react';
import {translate} from 'react-i18next';

import '../../css/home.scss';

class View extends Component {
    render() {
        const {
            t,
        } = this.props;
        return (
            <div className={'flex-center-vertical bar-padding flex-column'}>
                <div className={'error-404-big'}>404</div>
                <div className={'error-404-small'}>{t('error-page.page-not-found')}</div>
            </div>
        )
    }
}

export default translate('locale')(View);
