import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {HashLink as Link} from 'react-router-hash-link';

class View extends Component {
    render() {
        const t = this.props.t;
        const {i18n} = this.props;
        const lang = i18n.language;
        return (
            <div className="center solutions-bar bar-padding">
                <div className="max-width width ">
                    <div className={'left'}>
                        <div className={'main-title'}>
                            <div className={'main-max-width'}>
                                <div className={'text'}>
                                    <h1 dangerouslySetInnerHTML={{__html: t('solutions.main.title')}}/><br/>
                                    <font dangerouslySetInnerHTML={{__html: t('solutions.main.text-1')}}/><br/><br/>
                                    <font dangerouslySetInnerHTML={{__html: t('solutions.main.text-2')}}/><br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'solutions-blocks flex-row flex-wrap width'}>

                        <div className={'item i1'}>
                            <div className={'out'}>
                                <Link to={"/" + lang + "/solutions/#protection"}
                                      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                    <div className={'width'}>
                                        {t('solutions.main.blocks.text-1')}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={'item i2'}>
                            <div className={'out'}>
                                <Link to={"/" + lang + "/solutions/#investment"}
                                      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                    <div className={'width'}>
                                        {t('solutions.main.blocks.text-2')}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={'item i3'}>
                            <div className={'out'}>
                                <Link to={"/" + lang + "/solutions/#reliable"}
                                      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                    <div className={'width'}>
                                        {t('solutions.main.blocks.text-3')}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={'item i4'}>
                            <div className={'out'}>
                                <Link to={"/" + lang + "/solutions/#alternative"}
                                      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                    <div className={'width'}>
                                        {t('solutions.main.blocks.text-4')}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={'item i5'}>
                            <div className={'out'}>
                                <Link to={"/" + lang + "/solutions/#gift"}
                                      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                    <div className={'width'}>
                                        {t('solutions.main.blocks.text-5')}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={'item i6'}>
                            <div className={'out'}>
                                <Link to={"/" + lang + "/solutions/#protect"}
                                      scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'start'})}>
                                    <div className={'width'}>
                                        {t('solutions.main.blocks.text-6')}
                                    </div>
                                </Link>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        )
    }
}

export default translate('locale')(View);

