import React, {Component} from 'react';
import {translate} from 'react-i18next';

import '../../css/about.scss';
import '../../css/about-media.scss';

class View extends Component {
    render() {
        const t = this.props.t;
        return (
            <div className="center about-bar bar-padding">
                <div className="max-width width">
                    <div className={'left'}>
                        <div className={'logo-bar'}>
                            <div className={'flex-row width'}>
                                <div className={'div-text'}>
                                    <h1 dangerouslySetInnerHTML={{__html: t('about.title')}}/><br/>
                                    <text>

                                        <span dangerouslySetInnerHTML={{__html: t('about.text-1')}}/><br/><br/>
                                        <span dangerouslySetInnerHTML={{__html: t('about.text-2')}}/><br/><br/>
                                        <span dangerouslySetInnerHTML={{__html: t('about.text-3')}}/><br/><br/>
                                        <span dangerouslySetInnerHTML={{__html: t('about.text-4')}}/><br/><br/>

                                        <h3>{t('about.security.title')}</h3>
                                        <span dangerouslySetInnerHTML={{__html: t('about.security.text')}}/><br/><br/>
                                    </text>
                                </div>
                                <div className={'div-image center'}>
                                    <img src={'/img/gold.svg'} alt={''} className={'gold'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);

