import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
import {ToastContainer} from 'react-toastify';
import ScrollToTop from './modules/scrolltotop'
import {translate} from 'react-i18next';

import appRoutes from './routes.js';
import Error404 from "./views/404/404";

import './css/main.scss';
import './css/main-media.scss';

require('dotenv').config();

class App extends Component {
    render() {

        const {i18n} = this.props;
        let lang = i18n.language.split("-")[0];

        return (
            <div className="vbox viewport">

                <ToastContainer/>
                <ScrollToTop>
                    <Switch>

                        {
                            appRoutes.map((prop, key) => {
                                if (prop.redirect) {
                                    return (
                                        <Redirect from={prop.path} to={"/" + lang + prop.to} key={key}/>
                                    );
                                }
                                return (
                                    <PageRoute
                                        exact
                                        title={prop.title}
                                        path={prop.path}
                                        key={key}
                                        component={(props) => <prop.container
                                            {...props}
                                            comp={prop.comp}
                                            name={prop.name}
                                            link={prop.link}/>
                                        }
                                        comp={prop.comp}
                                    />
                                );
                            })
                        }

                        <Route component={Error404}/>

                    </Switch>
                </ScrollToTop>
            </div>
        );
    }
}

class PageRoute extends Route {
    componentDidMount() {
        document.title = "GOLD - ERC20 Stablecoin Backed by GOLD - " + this.props.title
    }

    componentDidUpdate() {
        document.title = "GOLD - ERC20 Stablecoin Backed by GOLD - " + this.props.title
    }

    render() {
        return <Route {...this.props}/>
    }
}

export default translate('locale')(App)


