
const langs = [
    {
        id: "en",
        name: "English",
        flag: "EN"
    },
    {
        id: "de",
        name: "Deutsch",
        flag: "DE"
    },
    {
        id: "es",
        name: "Espanol",
        flag: "ES"
    },
    {
        id: "fr",
        name: "Francais",
        flag: "FR"
    },
    {
        id: "id",
        name: "Indonesia",
        flag: "ID"
    },
    {
        id: "ru",
        name: "Русский",
        flag: "RU"
    },
    {
        id: "pl",
        name: "Polski",
        flag: "PL"
    },
    {
        id: "ro",
        name: "Românesc",
        flag: "RO"
    },
    {
        id: "tr",
        name: "Türk",
        flag: "TR"
    },
    {
        id: "zh",
        name: "中國",
        flag: "ZH"
    },
    {
        id: "ko",
        name: "한국인",
        flag: "KO"
    },
    {
        id: "hr",
        name: "Hrvatski",
        flag: "HR"
    },
    {
        id: "fil",
        name: "Filipino",
        flag: "FIL"
    },
    {
        id: "ja",
        name: "日本の",
        flag: "JA"
    }
];

export default langs;