import React from "react";
import {Component} from "react";
import {translate} from 'react-i18next';
import {axiosCatch, axiosinstance} from "../../modules/axios";
import * as EmailValidator from 'email-validator';


class View extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            validate: false,
            form: {
                email: "",
            },
            loading: false,
            success: false,
            error: false
        }
    }

    handleOnChange = (name, event) => {
        let validate = false;
        let form = this.state.form;
        form[name] = event.target.value;
        if (EmailValidator.validate(event.target.value)) {
            validate = true
        }
        this.setState({
            form: form,
            validate: validate
        });
    };

    load = () => {

        if (!EmailValidator.validate(this.state.form.email)) {
            return
        }

        if (this.state.loading) {
            return
        }

        this.setState({
            loading: true
        }, () => {
            axiosinstance({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + "/news/subscribe",
                params: {
                    email: this.state.form.email
                }
            })
                .then((response) => {
                    let ans = response.data;
                    if (ans.success === true) {
                        if (ans.data != null) {
                            this.setState({
                                loading: false,
                                form: {
                                    email: ""
                                },
                                success: true,
                                error: false
                            });
                        }
                    }
                }).catch((args) => {
                this.setState({
                    loading: false,
                    success: true,
                    //error: true
                });
                axiosCatch(args, this.props);
            });
        });

    };

    render() {

        const {
            t,
        } = this.props;

        const loading = this.state.loading;
        const validate = this.state.validate;
        const success = this.state.success;
        const error = this.state.error;

        return (
            <div className="center subscribe-block bar-padding ">
                <div className={'max-width width'}>
                    <div className="div-center width">

                        <div className={'flex-row '}>

                            <div>


                                <h2>{t('home.subscribe.title')}</h2>
                                <br/>


                                {success ? (

                                    <div className={'subscribe-success left'}>
                                        {t('home.subscribe.success')}
                                    </div>

                                ) : (

                                    <div className={'input substribe-div left'}>

                                        {/*<legend>{t('home.subscribe.email')}</legend>*/}
                                        <div className={'input width flex-row'}>

                                            <div className={''}>
                                                <input value={this.state.email}
                                                       placeholder={t('home.subscribe.email')}
                                                       type="text"
                                                       size={35}
                                                       onChange={(this.handleOnChange.bind(this, 'email'))}/>

                                            </div>
                                            {loading ? (
                                                <div>
                                                    <div className={'form-button left width'}>
                                                        <button className={'button-subscribe-loading ' + validate}>
                                                            {t('home.subscribe.go')}</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className={'form-button left width'}>
                                                        <button className={'button-subscribe ' + validate}
                                                                onClick={() => this.load()}>{t('home.subscribe.go')}</button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                        {error && (
                                            <div className={'subscribe-error'}>
                                                {t('home.subscribe.error')}
                                            </div>
                                        )}

                                    </div>

                                )}
                            </div>
                            <div className={'flex envelope-div flex-center-vertical'}>

                                <div className="envelope "/>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);