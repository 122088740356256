import React, {Component} from 'react';
import {translate} from 'react-i18next';
import { withRouter} from 'react-router'

import '../../css/home.scss';

class View extends Component {
    render() {
        const {i18n} = this.props;
        const {
            t,
        } = this.props;
        return (
            <div className={'center home-bar-strategy'}>
                <div className={'home-strategy'}>
                    <div className={'left-right-padding'}>
                        <div className={'max-width width'}>
                            <div className={'flex-row width div-wrap'}>
                                <div className={'text-block'}>
                                    <h3>{t('home.strategy.title')}</h3>
                                    <font>
                                        {t('home.strategy.text-1')}<br/><br/>
                                        {t('home.strategy.text-2')}<br/><br/>
                                        {t('home.strategy.text-3')}
                                    </font>
                                    <div className={'left'}>
                                        <div className={'button'}
                                             style={{margin: '60px 0 0 0'}} onClick={() => this.props.history.push("/" + i18n.language + "/market")}>{t('home.strategy.button-text')}</div>
                                    </div>
                                </div>

                                <div className={'flex image-block'}>
                                    {/*<div>*/}
                                        {/*<img src={'/img/strategy-image.svg'} className={'image'} alt={''}/>*/}
                                    {/*</div>*/}
                                    <div className={'strategy-image-text-div'}>
                                        <h4>{t('home.strategy.image.market-depth')}</h4>
                                        <div className={'reduction'} dangerouslySetInnerHTML={{ __html: t('home.strategy.image.reduction') }}/>
                                        <div className={'bid-ask'} dangerouslySetInnerHTML={{ __html: t('home.strategy.image.bid-ask') }}/>
                                        <div className={'flex-row width buy-sell'}>
                                            <div className={'flex center'}>
                                                {t('home.strategy.image.buy-orders')}
                                            </div>
                                            <div className={'flex center'}>
                                                {t('home.strategy.image.sell-orders')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('locale')(View));

