import React, {Component} from 'react';
import { translate } from 'react-i18next';
import { withRouter} from 'react-router'

class View extends Component {
    render() {
        const {i18n} = this.props;
        const {
            t,
        } = this.props;
        return (
            <div className={'center home-adv-gradient left-right-padding'}>
                <div className={'max-width home-adv '}>
                    <h2>{t('home.adv.title')}</h2>
                    <br/><br/><br/><br/>

                    <div className={'flex-row flex-wrap'}>
                        <div className={'adv-item'}>
                            <div className={'flex-row'}>
                                <div className={'image i1'}/>
                                <div className={'data'}>
                                    <b>{t('home.adv.block-1-title')}</b>
                                    <div className={'spacer'}/>
                                    <font dangerouslySetInnerHTML={{ __html: t('home.adv.block-1-text') }}/>
                                </div>
                            </div>
                        </div>

                        <div className={'adv-item'}>
                            <div className={'flex-row'}>
                                <div className={'image i2'}/>
                                <div className={'data'}>
                                    <b>{t('home.adv.block-2-title')}</b>
                                    <div className={'spacer'}/>
                                    <font dangerouslySetInnerHTML={{ __html: t('home.adv.block-2-text') }}/>
                                </div>
                            </div>
                        </div>


                        <div className={'adv-item'}>
                            <div className={'flex-row'}>
                                <div className={'image i3'}/>
                                <div className={'data'}>
                                    <b>{t('home.adv.block-3-title')}</b>
                                    <div className={'spacer'}/>
                                    <font dangerouslySetInnerHTML={{ __html: t('home.adv.block-3-text') }}/>
                                </div>
                            </div>
                        </div>

                        <div className={'adv-item'}>
                            <div className={'flex-row'}>
                                <div className={'image i4'}/>
                                <div className={'data'}>
                                    <b>{t('home.adv.block-4-title')}</b>
                                    <div className={'spacer'}/>
                                    <font dangerouslySetInnerHTML={{ __html: t('home.adv.block-4-text') }}/>
                                </div>
                            </div>
                        </div>


                        <div className={'adv-item'}>
                            <div className={'flex-row'}>
                                <div className={'image i5'}/>
                                <div className={'data'}>
                                    <b>{t('home.adv.block-5-title')}</b>
                                    <div className={'spacer'}/>
                                    <font dangerouslySetInnerHTML={{ __html: t('home.adv.block-5-text') }}/>
                                </div>
                            </div>
                        </div>

                        <div className={'adv-item'}>
                            <div className={'flex-row'}>
                                <div className={'image i6'}/>
                                <div className={'data'}>
                                    <b>{t('home.adv.block-6-title')}</b>
                                    <div className={'spacer'}/>
                                    <font dangerouslySetInnerHTML={{ __html: t('home.adv.block-6-text') }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'left'}>
                        <br/><br/>
                    <div className={'button'} onClick={() => this.props.history.push("/" + i18n.language + "/features")}>{t('home.adv.button-text')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('locale')(View));

