import React from "react";
import {Component} from "react";
import {translate} from 'react-i18next';

class View extends Component {

    render() {

        const {
            t,
        } = this.props;

        return (
            <div className="center flex-row home-purity-block">
                <div className={'flex'}/>
                <div className="div-center ">
                    {/*<h2>{t('home.purity.title')}</h2>*/}
                    <div className={'border-div'}>
                        <div className={'purity'}>
                            <span className={'text-big logo'}>
                                GOLD
                            </span>
                            <span className={'text-small '}>
                                ERC20
                            </span>
                            <span className={'text-big '} style={{marginLeft: '16px'}}>
                                = {t('home.purity.1-gram-of-gold')}
                            </span>
                            <span className={'text-small '}>
                                {t('home.purity.99-99-fine')}
                            </span>
                            <br/><br/><br/>
                            <span dangerouslySetInnerHTML={{__html: t('home.purity.text')}}/>
                        </div>

                    </div>
                </div>
                <div className={'flex'}/>
            </div>

        )
    }
}

export default translate('locale')(View);