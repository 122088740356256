import React, {Component} from 'react';
import {translate} from 'react-i18next';
import conf from '../../config.json'

import '../../css/fee.scss';
import '../../css/fee-media.scss';

class View extends Component {
    render() {
        const t = this.props.t;
        return (
            <div className="center fee-bar bar-padding">
                <div className="max-width width">
                    <div className={'left'}>
                        <div className={'logo-bar bar'}>
                            <div className={'flex-row width'}>
                                <div className={'div-text'}>
                                    <h1 dangerouslySetInnerHTML={{__html: t('fee.title')}}/><br/>
                                    <div className={'text'}>
                                        <font dangerouslySetInnerHTML={{__html: t('fee.text-1')}}/><br/><br/>
                                        <font dangerouslySetInnerHTML={{__html: t('fee.text-2', { 'annualFee': conf.annualFee})}}/><br/><br/>
                                    </div>
                                </div>
                                <div className={'div-image center'}>
                                    <img src={'/img/gold.svg'} alt={''} className={'gold'}/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <table className="table-fee">
                        <thead>
                        <tr>
                            <th>{t('fee.table.comparison')}</th>
                            <th>GOLD</th>
                            <th className={'small'}>USDT</th>
                            <th className={'small'}>TUSD</th>
                            <th className={'small'}>PAX</th>
                            <th className={'small'}>USDC</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td aria-label={t('fee.table.comparison')} className={'resolution-th'}>{t('fee.table.risk-if-assets-freeze')}</td>
                            <td aria-label="GOLD" className={'green'}>{t('fee.table.no')}</td>
                            <td aria-label="USDT" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="TUSD" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="PAX" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="USDC" className={'red'}>{t('fee.table.high')}</td>s
                        </tr>

                        <tr>
                            <td aria-label={t('fee.table.comparison')} className={'resolution-th'}>{t('fee.table.dependace-on-banks')}</td>
                            <td aria-label="GOLD" className={'green'}>{t('fee.table.no')}</td>
                            <td aria-label="USDT" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="TUSD" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="PAX" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="USDC" className={'red'}>{t('fee.table.high')}</td>s
                        </tr>

                        <tr>
                            <td aria-label={t('fee.table.comparison')} className={'resolution-th'}>{t('fee.table.dependace-on-government')}</td>
                            <td aria-label="GOLD" className={'green'}>{t('fee.table.no')}</td>
                            <td aria-label="USDT" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="TUSD" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="PAX" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="USDC" className={'red'}>{t('fee.table.high')}</td>s
                        </tr>

                        <tr>
                            <td aria-label={t('fee.table.comparison')} className={'resolution-th'}>{t('fee.table.dependace-on-financial')}</td>
                            <td aria-label="GOLD" className={'green'}>{t('fee.table.close_to_zero')}</td>
                            <td aria-label="USDT" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="TUSD" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="PAX" className={'red'}>{t('fee.table.high')}</td>
                            <td aria-label="USDC" className={'red'}>{t('fee.table.high')}</td>s
                        </tr>

                        <tr>
                            <td aria-label={t('fee.table.comparison')} className={'resolution-th'}>{t('fee.table.assets')}</td>
                            <td aria-label="GOLD" className={'green'}>{t('fee.table.yes')}</td>
                            <td aria-label="USDT" className={'red'}>{t('fee.table.no')}</td>
                            <td aria-label="TUSD" className={'red'}>{t('fee.table.yes')}</td>
                            <td aria-label="PAX" className={'red'}>{t('fee.table.no')}</td>
                            <td aria-label="USDC" className={'red'}>{t('fee.table.no')}</td>s
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);

