import React, {Component} from 'react';
import {translate} from 'react-i18next';

import '../../css/terms.scss';

class View extends Component {
    render() {
        const t = this.props.t;
        return (
            <div>
                <div className="center terms-bar bar-padding">
                    <div className="max-width width">
                        <div className={'left'}>
                            <div className={'div-text'}>
                                <h1 dangerouslySetInnerHTML={{__html: t('terms.main.title')}}/><br/>
                                <div className='text'>
                                    <span dangerouslySetInnerHTML={{__html: t('terms.main.updates')}}/><br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="center terms-bar-text bar-padding">
                    <div className="max-width width">
                        <div className={'left'}>
                            <div className={'div-text'}>
                                <h3 dangerouslySetInnerHTML={{__html: t('terms.acceptance.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.acceptance.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.eligibility.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.eligibility.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.general.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.general.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.ownership.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.ownership.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.fees.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.fees.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.risk.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.risk.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.no-advise.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.no-advise.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.system-availability.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.system-availability.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.taxes.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.taxes.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.restrictions.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.restrictions.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.irreversible-transactions.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.irreversible-transactions.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.intellectual-property.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.intellectual-property.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.links.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.links.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.termination.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.termination.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.disclaimer.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.disclaimer.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.idemnity.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.idemnity.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.modifications.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.modifications.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('terms.contact.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('terms.contact.text')}}/>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);

