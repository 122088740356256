import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router'

class View extends Component {
    render() {
        // const {i18n} = this.props;
        const {
            t,
        } = this.props;
        return (

            <div className={'div-preadvisors width bar-padding'}>
                <div className={'max-width width'}>
                    <div className={'width'}>
                        <div className={'advisor-h2'}>{t('home.advisors.title')}</div>
                        <br/>
                        <div className={'flex-row flex-wrap'}>
                            <div className={'advisor'}>
                                <div className={'preimg'}><div className={'img tonetto'}/></div>
                                <div className={'adv-name'}>Walter Tonetto</div>
                                <div className={'url'}><a target={'_new'} href={'https://www.linkedin.com/in/waltertonetto'}>Linkedin</a></div>
                            </div>
                            <div className={'advisor'}>
                                <div className={'preimg'}><div className={'img scott'}/></div>
                                <div className={'adv-name'}>Paul Scott</div>
                                <div className={'url'}><a target={'_new'} href={'https://www.linkedin.com/in/paulscott55'}>Linkedin</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('locale')(View));

