import React, {Component} from 'react';
import { translate } from 'react-i18next';

import '../../css/home-anim.scss';

class View extends Component {
    render() {
        const {
            t,
        } = this.props;
        return (
            <div>
                <div className="center flex-row home-main-block ">
                    <div className={'div-left'}/>
                    <div className="div-center">
                        <div className={'home_block_main'}>
                            <div className={'spacer'}/>
                            <div className={'flex-row '}>
                                <div className={'left-block '}>
                                    <h1><div dangerouslySetInnerHTML={{ __html: t('home.main.title') }}/></h1>
                                    <br/>
                                    <h3>{t('home.main.title-low')}</h3><br/>
                                    <font>
                                        <div className={'home-main-block-text'}>
                                            <font><span dangerouslySetInnerHTML={{ __html: t('home.main.text-1') }}/><br/><br/></font>
                                            <font dangerouslySetInnerHTML={{ __html: t('home.main.text-2') }}/>
                                        </div>
                                    </font>
                                </div>
                                <div className={'right-block'}>
                                    <img src={'/img/key.svg'} alt={''} className={'key key-anim'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'div-right'}/>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);