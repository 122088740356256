import React, {Component} from 'react';

import Footer from "./Footer.js";
import Header from "./Header.js";
import i18n from "../i18n";
import langs from './../i18n/list'

class AppPublic extends Component {

    render() {

        let MyComponent = this.props.comp;
        let lang = this.props.match.params.lang;
        console.log(i18n.languages);

        let err = true;
        for (let i = 0; i < langs.length; i++) {
            let item = langs[i];
            if (item.id === lang) {
                err = false;
            }
        }

        //console.log('LANG!!!: ' + lang + " " + err);

        if (err || lang === 'undefined') {

            lang = 'en';
            if (this.props.link === "") {
                i18n.changeLanguage(lang);
                window.location.href = '/' + lang + this.props.link;
                return
            }
        }

        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);
        }

        return (
            <div className="vbox viewport center">
                <Header page={this.props.page}  {...this.props}/>
                <div className="main content">
                    <MyComponent setErrorPage={this.props.setErrorPage} {...this.props}/>
                </div>
                <Footer {...this.props}/>
            </div>
        )
    }
}

export default AppPublic;

