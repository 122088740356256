import React, {Component} from 'react';
import '../css/loading.css';

class View extends Component {
    render() {
        return (
            <div className={'loading ' + this.props.color + " " + this.props.size}/>
        )
    }
}

export default (View);
