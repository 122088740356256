import React, {Component} from 'react';
import {translate} from 'react-i18next';

import '../../css/feat.scss';
import '../../css/feat-media.scss';

class View extends Component {
    render() {
        const t = this.props.t;
        return (
            <div className="center features-bar bar-padding">
                <div className="max-width width">
                    <div className={'main-title left width'}>

                        <h1>{t('features.title')}</h1>

                        <div className={'features width'}>

                            <div className={'block'}>
                                <div className={'flex-row width'}>
                                    <div className={'img i1'}/>
                                    <div className={'title'}>{t('features.block-safe.title')}</div>
                                </div>
                                <div className={'text'}  dangerouslySetInnerHTML={{__html: t('features.block-safe.text')}}/>
                            </div>

                            <div className={'block'}>
                                <div className={'flex-row'}>
                                    <div className={'img i2'}/>
                                    <div className={'title'}>{t('features.block-no-fee.title')}</div>
                                </div>
                                <div className={'text'}  dangerouslySetInnerHTML={{__html: t('features.block-no-fee.text')}}/>
                            </div>

                            <div className={'block'}>
                                <div className={'flex-row'}>
                                    <div className={'img i3'}/>
                                    <div className={'title'}>{t('features.block-secure.title')}</div>
                                </div>
                                <div className={'text'}  dangerouslySetInnerHTML={{__html: t('features.block-secure.text')}}/>
                            </div>

                            <div className={'block'}>
                                <div className={'flex-row'}>
                                    <div className={'img i4'}/>
                                    <div className={'title'}>{t('features.block-liquidity.title')}</div>
                                </div>
                                <div className={'text'}  dangerouslySetInnerHTML={{__html: t('features.block-liquidity.text')}}/>
                            </div>

                            <div className={'block'}>
                                <div className={'flex-row'}>
                                    <div className={'img i5'}/>
                                    <div className={'title'}>{t('features.block-privacy.title')}</div>
                                </div>
                                <div className={'text'}  dangerouslySetInnerHTML={{__html: t('features.block-privacy.text')}}/>
                            </div>

                            <div className={'block'}>
                                <div className={'flex-row'}>
                                    <div className={'img i6'}/>
                                    <div className={'title'}>{t('features.block-lindy.title')}</div>
                                </div>
                                <div className={'text'}  dangerouslySetInnerHTML={{__html: t('features.block-lindy.text')}}/>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);

