import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {axiosCatch, axiosinstance} from "../../modules/axios";
import {numFixed} from '../../modules/numeric';
import * as EmailValidator from 'email-validator';
import Tip from '../../modules/tip.js';
import {withRouter} from 'react-router'
import Loading from './../../modules/loading'
import Order from './MarketPlaceOrder';


class View extends Component {
  render() {
    return (
      <div>
        <div className={'market-bar'}>
          <In {...this.props}/>
        </div>
      </div>
    )
  }
}

class In extends Component {

  constructor(props, context) {
    super(props, context);
    //console.log('constructor');
    this.state = {
      first_load: false,
      id: 0,
      loading_error: this.props.loading_error,
      loading_info: this.props.loading_info,
      loading: false,
      form: this.props.form,
      rate: this.props.rate,
      currency_send: this.props.currency_send,
      currency_get: this.props.currency_get,
      show_send_select: false,
      show_get_select: false,
      currency_from: this.props.currency_from,
      currency_to: this.props.currency_to,
      no_enabled_rates: true,
      errorForm: {
        send: "",
        get: "",
        to_address: "",
        refund_address: "",
        email: "",
      },
      checked: false,
      tooltip: {
        to_address: false,
        refund_address: false,
        send_max_min: false,
        email: false
      },
      notify: {
        show: false,
        text: ""
      },
      order_id: this.props.match.params.id,
      order_created: false,
      order_error: {
        state: false,
        text: ""
      }
    }
  }

  componentWillReceiveProps(new_props) {
    if (new_props.id !== this.props.id) {
      //console.log("currency_from: " + new_props.currency_from);
      if (!this.state.first_load) {
        this.setState({
          ...new_props,
          first_load: true,
        }, () => {
          if (!new_props.loading_error) {
            this.updateInputs("");
          }
        });
      } else {
        this.setState({
          id: new_props.id,
          rate: new_props.rate,
          loading_error: new_props.loading_error,
          loading_info: new_props.loading_info,
          no_enabled_rates: new_props.no_enabled_rates,
          spot: new_props.spot
        }, () => {
          if (!new_props.loading_error) {
            //console.log("after step2 form: " + JSON.stringify(this.state.form));
            this.updateInputs("");
          }
        });
      }
    }
  }

  hideNotify() {
    let notify = {
      show: false,
      text: "",
    };
    this.setState({
      notify: notify,
    })
  }

  showNotify(text) {
    let notify = {
      show: true,
      text: text,
    };
    this.setState({
      notify: notify,
    })
  }

  tofixed = (val, num) => {
    return val.toFixed(num).replace(/\.?0*$/, '');
  };

  showSend = () => {
    //console.log('showSend');
    if (!this.state.show_send_select) {
      this.setState({
        show_send_select: true
      })
    }
  };

  showGet = () => {
    //console.log('showGet');
    if (!this.state.show_get_select) {
      this.setState({
        show_get_select: true
      })
    }
  };

  hideSelect = () => {
    this.setState({
      show_send_select: false,
      show_get_select: false,
    })
  };

  change = () => {
    let old_get_symbol = this.state.currency_to;
    let old_send_symbol = this.state.currency_from;
    let form = this.state.form;
    form["get"] = [form["send"], form["send"] = form["get"]][0];
    this.setState({
      show_send_select: false,
      show_get_select: false,
      currency_to: old_send_symbol,
      currency_from: old_get_symbol,
      form: form,
    }, () => {
      this.updateInputs("");
    });
  };

  setPairSend = (value) => {
    setTimeout(() => {
      this.hideForm();
      if (value === "gold") {
        let alernative = this.state.currency_from;
        if (alernative === 'gold') {
          this.setState({
            show_send_select: false,
            show_get_select: false,
          });
          return;
        }
        this.setState({
          show_send_select: false,
          show_get_select: false,
          currency_from: value,
          currency_to: alernative,
        }, () => {
          this.updateInputs("")
        });
      } else {
        this.setState({
          show_send_select: false,
          show_get_select: false,
          currency_from: value,
          currency_to: "gold",
        }, () => {
          this.updateInputs("")
        });
      }
    }, 100);
  };

  setPairGet = (value) => {
    this.hideForm();
    setTimeout(() => {
      if (value === "gold") {
        let alernative = this.state.currency_to;
        if (alernative === 'gold') {
          this.setState({
            show_send_select: false,
            show_get_select: false,
          });
          return
        }
        this.setState({
          show_send_select: false,
          show_get_select: false,
          currency_to: value,
          currency_from: alernative,
        }, () => {
          this.updateInputs("")
        });
      } else {
        this.setState({
          show_send_select: false,
          show_get_select: false,
          currency_to: value,
          currency_from: "gold",
        }, () => {
          this.updateInputs("")
        });
      }
    }, 100);
  };

  getRef = () => {
    if (
      typeof window !== 'undefined' &&
      window !== null &&
      window.document &&
      window.document.referrer
    ) {
      return window.document.referrer
    }
    return ""
  };

  makeOrder = () => {
    let form = this.state.form;
    this.setState({
      loading: true
    });


    let getLang = localStorage.getItem('i18nextLng');
    let obj_rate = this.state.rate.get(this.state.currency_from + "_" + this.state.currency_to);
    axiosinstance({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_EXCHANGE_URL + "/exchange.json",
      data: {
        amount: form.send,
        currency_from: this.state.currency_from,
        currency_to: this.state.currency_to,
        address: form.to_address,
        accept_privacy_policy: this.state.checked,
        rate: obj_rate["rate"],
        email: form.email,
        refund_address: form.refund_address,
        lang: getLang,
        referer: this.getRef()
      }
    })
      .then((response) => {
        let ans = response.data;
        if (ans.success === true) {

          if (!this.state.order_created) {
            const node = this.refs.trackerRef;
            node && node.scrollIntoView({block: "start", behavior: 'smooth'});
          }

          this.setState({
            order_id: ans.token,
            order_created: true
          })

          //this.props.history.push("/market/" + ans.token);

        }
      }).catch((args) => {
      if (!args.response) {
        this.setState({
          loading: false,
          order_error: {
            state: true,
            text: "Timeout error"
          }
        });
      } else {
        let ans = args.response.data;
        if (ans.success === false) {
          this.setState({
            loading: false,
            order_error: {
              state: true,
              text: ans.message
            }
          });
        }
      }

      //alert('market.error');
      //console.log(args);
      axiosCatch(args, this.props);
    });
  };


  handleOnChange = (name, event) => {
    switch (name) {
      case 'send':
        let form = this.state.form;
        form["send"] = event.target.value;
        this.setState({
          form: form
        }, () => {
          this.updateInputs("send");
        });
        break;
      case 'get':
        if (!event.target.validity.valid) {
          return
        }
        let form2 = this.state.form;
        form2["get"] = event.target.value;
        this.setState({
          form: form2
        }, () => {
          this.updateInputs("get");
        });
        break;
      case 'to_address':
        if (!this.validate_address(this.state.currency_to, event.target.value)) {
          this.setError("to_address", true)
        } else {
          this.setError("to_address", false)
        }
        this.update(name, event);
        break;
      case 'refund_address':
        if (event.target.value.length > 0) {
          if (!this.validate_address(this.state.currency_from, event.target.value)) {
            this.setError("refund_address", true)
          } else {
            this.setError("refund_address", false)
          }
        } else {
          this.setError("refund_address", false)
        }
        this.update(name, event);
        break;
      case 'email':
        if (event.target.value.length > 0) {
          if (!EmailValidator.validate(event.target.value)) {
            this.setError("email", true)
          } else {
            this.setError("email", false)
          }
        } else {
          this.setError("email", false)
        }
        this.update(name, event);
        break;
      default:
        break;
    }
  };

  updateInputs(from) {

    if (this.state.no_enabled_rates) {
      return
    }

    let form = {...this.state.form};

    if (from === "send" || from === "") {
      //console.log(this.state.currency_from + "_" + this.state.currency_to);
      let obj_rate = this.state.rate.get(this.state.currency_from + "_" + this.state.currency_to);
      //console.log(obj_rate["rate"] * this.state.form.send);
      form["get"] = this.tofixed(obj_rate["rate"] * this.state.form.send, obj_rate["decimals_to"]);
    }

    if (from === "get") {
      let obj_rate = this.state.rate.get(this.state.currency_from + "_" + this.state.currency_to);
      form["send"] = this.tofixed(1 / obj_rate["rate"] * this.state.form.get, obj_rate["decimals_from"]);
    }

    this.setState({
      form: form
    }, () => {
      //console.log("form " + JSON.stringify(this.state.form))
    });

  }

  uuidValidate(uuid) {
    return uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i) !== null;
  }

  update(name, event) {
    let form = this.state.form;
    form[name] = event.target.value;
    this.setState({
      form: form
    });
  }
  ;

  submitForm() {
    let error = false;

    if (!this.state.checked) {
      error = true
    }

    if (!this.validate_address(this.state.currency_to, this.state.form.to_address)) {
      this.setError("to_address", true);
      this.showTooltip("to_address", true);
      //console.log(this.state.form.to_address.length + " / " + this.state.size.get(this.state.currency_to) + " / " + this.state.currency_to  + " / " + this.validate_address(this.state.currency_to, this.state.form.to_address));
      error = true
    }

    if (this.state.form.refund_address.length > 0) {
      if (!this.validate_address(this.state.currency_from, this.state.form.refund_address)) {
        this.setError("refund_address", true);
        this.showTooltip("refund_address", true);
        error = true
      }
    }

    let obj_rate = this.state.rate.get(this.state.currency_from + "_" + this.state.currency_to);

    if (this.state.form.send < obj_rate["min_amount"]) {
      this.showTooltip("send_max_min", true);
      error = true
    }

    if (this.state.form.send > obj_rate["max_amount"]) {
      this.showTooltip("send_max_min", true);
      error = true
    }

    if (this.state.form.email.length > 0) {
      if (!EmailValidator.validate(this.state.form.email)) {
        this.showTooltip("email", true);
        this.setError("email", true);
        error = true
      }
    }
    if (this.state.form.send === 0) {
      return
    }
    if (this.state.form.get === 0) {
      return
    }

    if (error) {
      return
    }

    this.makeOrder();
  }
  ;

  toggleChangeEnable = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  upper = (val) => {
    return (
      <span>{val.toString().toUpperCase()}</span>
    )
  };

  showForm = () => {

    if (!this.state.loading_info) {
      return
    }

    let obj_rate = this.state.rate.get(this.state.currency_from + "_" + this.state.currency_to);

    if (!obj_rate.enabled) {
      this.showNotify(this.props.t('market.notify.disabled'));
      this.hideForm();
      return;
    }

    //console.log(this.state.form.send + "/" + obj_rate["min_amount"] + " / " + obj_rate["max_amount"]);
    if (this.state.form.send < obj_rate["min_amount"]) {
      this.showTooltip("send_max_min", true);
      return;
    }

    if (this.state.form.send > obj_rate["max_amount"]) {
      this.showTooltip("send_max_min", true);
      return;
    }

    let form = this.state.form;
    form.show = true;
    this.setState({
      form: form
    }, () => {

    });
  };

  hideForm = () => {
    this.setState({
      errorForm: {
        send: "",
        get: "",
        to_address: "",
        refund_address: "",
        email: "",
      },
      form: {
        show: false,
        send: 1,
        get: 0,
        to_address: "",
        refund_address: "",
        email: ""
      },
    }, () => {
      this.updateInputs("");
    });
  };

  setError = (input, flag) => {
    let setFlag;
    if (flag === true) {
      setFlag = "error";
    } else {
      this.showTooltip(input, false);
      setFlag = "";
    }
    let formErr = this.state.errorForm;
    formErr[input] = setFlag;
    this.setState({
      formError: formErr
    });
  };

  showTooltip = (target, bool) => {
    //console.log(JSON.stringify(this.state.currency_from));
    let tooltip = this.state.tooltip;
    tooltip[target] = bool;
    this.setState({
      tooltip: tooltip
    })
  };

  validate_address = (currency, address) => {
    switch (currency) {
      case "btc":
        return address.match(/^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/) !== null;
      case "eth":
        return address.match(/^0x[a-fA-F0-9]{40}$/) !== null;
      case "gold":
        return address.match(/^0x[a-fA-F0-9]{40}$/) !== null;
      default:
        return null
    }
  };

  render() {

    const {
      t,
    } = this.props;

    if (!this.state.loading_error && !this.state.no_enabled_rates) {

      const loading = this.state.loading_info;

      let obj_rate;
      if (loading) {
        obj_rate = this.state.rate.get(this.state.currency_from + "_" + this.state.currency_to);
      }

      let currency_send = this.state.currency_send.map(item =>
        (
          <div key={item.name + '_send'} className={'market-select2 ' + item.name}
               onClick={() => this.setPairSend(item.name)}/>
        ));

      let currency_get = this.state.currency_get.map(item =>
        (
          <div key={item.name + '_get'} className={'market-select2 ' + item.name}
               onClick={() => this.setPairGet(item.name)}/>
        ));


      const notify = this.state.notify;

      return (
        <div className={'bar-padding-without-top'}>

          <div className={'max-width z-index2 width'}>
            <div id='#tracker' ref="trackerRef" className={'tracker width'}>
              <br/>
              <h4>{t('market.main.subject')}</h4>
              <div className={'text'} dangerouslySetInnerHTML={{__html: t('market.main.text-2')}}/>
            </div>
          </div>

          {this.state.order_id === undefined || !this.uuidValidate(this.state.order_id) ? (

            <div className={''}>

              {notify.show && (
                <div className={'notify'}>
                  <div className={'notify-in'}>
                    {notify.text}
                    <br/><br/>
                    <div className={'notify-button'}
                         onClick={() => this.hideNotify()}>{t('market.notify.close')}</div>
                  </div>
                </div>
              )}

              <div className={'max-width market-div z-index2 width'}>
                <div className={'flex-row width resolution-flex'}>
                  <div className={' left market-item'}>
                    <div className={'legend'}>{t('market.form.you_send')}</div>

                    {loading ? (

                      <Tip
                        text={t('market.tooltip.min_amount') + ' ' + obj_rate["min_amount"] + ' ' + this.state.currency_from.toUpperCase() +
                        ' / ' + t('market.tooltip.max_amount') + ' ' + obj_rate["max_amount"] + ' ' + this.state.currency_from.toUpperCase()}
                        show={this.state.tooltip.send_max_min}
                        action={() => this.showTooltip('send_max_min', false)}/>

                    ) : null}

                    <div className={'input flex-row'}>

                      {loading ? (
                        <input value={this.state.form.send}
                               type="text"
                               size={12}
                               onChange={numFixed(obj_rate["decimals_from"])(this.handleOnChange.bind(this, 'send'))}/>
                      ) : (
                        <div className={'width flex-center-vertical'}><Loading/></div>
                      )}

                      <div className={'market-select ' + this.state.currency_from}
                           onClick={this.showSend}>
                        {this.state.show_send_select ? (
                          <div className={'deselector'}
                               onMouseLeave={this.hideSelect}>
                            <div className={'preselector'}>{currency_send}</div>
                          </div>
                        ) : null}
                      </div>

                    </div>

                    <div className={'rate up'}>
                      {loading ? (
                        <span>1 {this.state.currency_from} = {this.tofixed(obj_rate["rate"], obj_rate["decimals_to"])} {this.state.currency_to}</span>
                      ) : null}
                    </div>

                  </div>
                  <div className={'market-item-change'}>
                    <img src={'/img/change.svg'} className={'img-change'} alt={''}
                         onClick={this.change}/>
                  </div>
                  <div className={' left market-item item-margintop'}>
                    <div className={'legend'}>{t('market.form.you_get')}</div>
                    <div className={'input flex-row'}>
                      {loading ? (
                        <input value={this.state.form.get}
                               size={12}
                               type="text"
                               onChange={numFixed(obj_rate["decimals_to"])(this.handleOnChange.bind(this, 'get'))}/>
                      ) : (
                        <div className={'width flex-center-vertical'}><Loading/></div>
                      )}
                      <div className={'market-select ' + this.state.currency_to}
                           onClick={this.showGet}>
                        {this.state.show_get_select ? (
                          <div className={'deselector'}
                               onMouseLeave={this.hideSelect}>
                            <div className={'preselector'}>{currency_get}</div>
                          </div>
                        ) : (null)
                        }
                      </div>
                    </div>

                    <div className={'rate up'}>
                      {loading ? (
                        <span>1 {this.state.currency_to} = {this.tofixed(1 / obj_rate["rate"], obj_rate["decimals_from"])} {this.state.currency_from}</span>
                      ) : null}
                    </div>

                  </div>

                  <div className={'market-item-button'}>
                    <div className={'legend'}>&nbsp;</div>
                    <button className={'button-exchange speed-animate'}
                            onClick={this.showForm}>{t('market.form.button')}</button>
                    <div className={'rate'}>&nbsp;</div>
                  </div>

                </div>

              </div>

              {this.state.form.show ? (
                <div className={'max-width market-div-additional z-index1 width'}>
                  <div className={'flex-row width flex-wrap'}>

                    <div className={'left form i1'}>
                      <div className={'legend'}>{t('market.form.refund-address')}</div>
                      <div className={'input'}>

                        <Tip text={t('market.tooltip.address_incorrect')}
                             show={this.state.tooltip.refund_address}
                             action={() => this.showTooltip('refund_address', false)}/>

                        <input value={this.state.form.refund_address}
                               placeholder={this.state.currency_from.toUpperCase() + " " + t('market.form.placeholder.refund_address')}
                               className={this.state.errorForm.refund_address}
                               onChange={this.handleOnChange.bind(this, 'refund_address')}/>

                      </div>
                    </div>

                    <div className={'left form i2'}>
                      <div className={'legend'}>{t('market.form.recipient-address')}</div>
                      <div className={'input'}>

                        <Tip text={t('market.tooltip.address_incorrect')}
                             show={this.state.tooltip.to_address}
                             action={() => this.showTooltip('to_address', false)}/>

                        {this.state.currency_to === "gold" ? (

                          <input value={this.state.form.to_address}
                                 placeholder={this.state.currency_to.toUpperCase() + " " + t('market.form.placeholder.to_address') + " " + t('market.form.placeholder.erc')}
                                 className={this.state.errorForm.to_address}
                                 onChange={this.handleOnChange.bind(this, 'to_address')}/>

                        ) : (

                          <input value={this.state.form.to_address}
                                 placeholder={this.state.currency_to.toUpperCase() + " " + t('market.form.placeholder.to_address')}
                                 className={this.state.errorForm.to_address}
                                 onChange={this.handleOnChange.bind(this, 'to_address')}/>

                        )}

                      </div>
                    </div>

                  </div>
                  <div className={'flex-row width flex-wrap'}>
                    <div className={'left form i3'}>
                      <div className={'legend'}>{t('market.form.email')}</div>

                      <Tip text={t('market.tooltip.not-valid')}
                           show={this.state.tooltip.email}
                           action={() => this.showTooltip('email', false)}/>

                      <div className={'input'}>
                        <input value={this.state.form.email}
                               className={this.state.errorForm.email}
                               placeholder={t('market.form.placeholder.e-mail')}
                               onChange={this.handleOnChange.bind(this, 'email')}/>
                      </div>
                    </div>
                    <div className={'form i4'}>
                      &nbsp;
                    </div>
                  </div>
                  <br/>
                  <div className={'align-left width'}>

                    <input type="checkbox" className="checkbox" id="checkbox"
                           onChange={this.toggleChangeEnable}
                           checked={this.state.checked}/>
                    <label htmlFor="checkbox"/>
                    <label className={'check_label pointer'}
                           onClick={this.toggleChangeEnable}>{t('market.form.confirm_privacy')}</label>

                    <div className={'form-button'}>
                      <button className={'button-back speed-animate'}
                              onClick={() => this.hideForm()}>{t('market.form.cancel')}</button>
                      {this.state.loading}
                      {!this.state.loading ? (
                        <button
                          className={'button-exchange speed-animate ' + this.state.checked.toString()}
                          onClick={() => this.submitForm()}>{t('market.form.submit')}</button>
                      ) : (
                        <button
                          className={'button-exchange-loading speed-animate ' + this.state.checked.toString()}>{t('market.form.submit')}</button>
                      )}
                    </div>

                    {this.state.order_error.state && (
                      <div className={'message-error'}>
                        {this.state.order_error.text}
                      </div>
                    )}

                  </div>

                </div>
              ) : null
              }

            </div>

          ) : (

            <div>
              <Order order_id={this.state.order_id}/>
            </div>

          )}


        </div>
      )
    }

    if (this.state.no_enabled_rates) {
      return (
        <div className={'max-width market-div z-index2 width'}>
          <div className={'div_error'}>
            {t('market.no-rates')}
          </div>
        </div>
      )
    }

    // if (this.state.loading_error) {
    //     return (
    //         <div>
    //             <div className={'div-loading-preloader'}>
    //                 <div className={'div-loading flex-center-vertical'}>
    //                     <div className={'message-error'}>{this.state.error}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

  }
}

export default withRouter(translate('locale')(View));
