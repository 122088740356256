import React, {Component} from 'react';
import {translate} from 'react-i18next';

import windowSize from "react-window-size";
import {withRouter} from 'react-router'

import '../../css/news.scss';
import '../../css/news-media.scss';
import {axiosCatch, axiosinstance} from "../../modules/axios";

class View extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            news: [],
            news_item: {}
        }
    }

    componentDidMount() {
        this.load();
    };

    load = () => {
        const {i18n} = this.props;
        axiosinstance({
            method: 'get',
            url: "/locales/" + i18n.language + "/news_v2.json",
        })
            .then((response) => {
                let ans = response.data;
                let news = ans.news.reverse();
                this.setState({
                    news: news
                });
                for (let j = 0; j < news.length; j++) {
                    if (news[j].id.toString() === this.props.match.params.id) {
                        this.setState({
                            news_item: news[j]
                        });
                        return
                    }
                }
            }).catch((args) => {
            axiosCatch(args, this.props);
        });
    };

    press_news = (text) => {
        if (text === "undefined") {
            return
        }
        text = text.replace(/\\</gi, '');
        text = text.replace(/\\>/gi, '');
        text = text.replace(
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
            '<a target="_new" class="url" href="$1">$1</a>'
        );
        text = text.replace(/\n/gi, '<br/>');
        return text;
    };

    render() {
        const {i18n} = this.props;

        const {
            t,
        } = this.props;

        return (
            <div>
                <div className={'news-item-bar width bar-padding'}>
                    <div className={'max-width width'}>
                        <div className={'left width'}>
                            {Object.keys(this.state.news_item).length > 0 && (
                                <div>

                                    <div className={'time'}>{this.state.news_item.date}</div>

                                    <h1>{this.state.news_item.subject}</h1>
                                    <br/>
                                    <div className={'text'}
                                         dangerouslySetInnerHTML={{__html: this.press_news(this.state.news_item.text)}}/>
                                    <br/><br/><br/>
                                    <div className={'center'}>
                                        <button className={'button-back'}
                                                onClick={() => this.props.history.push("/" + i18n.language + "/news")}>{t('locale:news.button')}</button>
                                    </div>
                                    <br/><br/><br/>
                                </div>
                            )}

                            <DivNews news={this.state.news} {...this.props}/>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default withRouter(windowSize(translate('')(View)));


class DivNews extends Component {
    subject;
    preview;

    render() {

        const {
            t,
        } = this.props;

        let news = this.props.news;


        if (this.props.windowWidth < 1060 && this.props.windowWidth > 768) {
            news = news.slice(0, 4)
        } else {
            news = news.slice(0, 3)
        }

        return (

            <div style={{padding: '50px 0'}} className={'width'}>

                <h2>{t('locale:news.title')}</h2>
                <div className={'flex-row flex-wrap item-news width'}>

                    {news.map(item =>
                        <Item {...this.props} news={item} key={item.id}/>
                    )}

                </div>
            </div>

        )

    }

}


class Item extends Component {
    render() {

        const item = this.props.news;
        const {i18n} = this.props;

        let lengNews = 200;

        let preview = item.preview.substring(0, lengNews);
        let preview2 = item.preview.substring(lengNews, 1000);
        let preview3 = preview2.split(" ");
        preview = preview + preview3[0] + "...";

        return (
            <div className={'item'}>
                <div className={'pre_image pointer'}
                     onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>
                    <div style={{
                        backgroundImage: `url(/img/news/news${item.id}.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                    }} className={'image n1'}/>
                </div>

                <div className={'text'}>
                    <b className={'pointer up'}
                       onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>{item.subject}</b><br/>
                    <br/>
                    <div className={'pointer'}
                         onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>
                        {preview.split("\\n").map(function (item, idx) {
                                return (
                                    <font key={idx}>
                                        {item}
                                        <br/>
                                    </font>
                                )
                            })
                        }
                    </div>

                    <span className={'time'}>{item.date}</span>

                </div>

            </div>
        )
    }
}


