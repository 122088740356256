import React, {Component} from 'react';

import '../../css/solutions.scss';
import '../../css/solutions-media.scss';

import Main from './Main.js';
import BoxItem from './BoxItem.js';
import GoldToken from '../GoldFee/GoldFee.js';

class View extends Component {
    render() {
        return (
            <div className={'solutions'}>
                <Main/>
                <BoxItem content={'protection'} />
                <BoxItem content={'investment'}/>
                <BoxItem content={'reliable'}/>
                <BoxItem content={'alternative'}/>
                <BoxItem content={'gift'}/>
                <BoxItem content={'protect'}/>
                <GoldToken source={'solutions'}/>
            </div>
        )
    }
}

export default View;

