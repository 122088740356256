import React, {Component} from 'react';
import windowSize from 'react-window-size';
import {translate} from 'react-i18next';
import {withRouter} from "react-router";
import {axiosCatch, axiosinstance} from "../../modules/axios";

class View extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            news: []
        }
    }

    componentDidMount() {
        this.load();
    };

    load = () => {
        const {i18n} = this.props;
        axiosinstance({
            method: 'get',
            url: "/locales/" + i18n.language + "/news_v2.json",
        })
            .then((response) => {
                let ans = response.data;

                this.setState({
                    news: ans.news.reverse(),
                });

            }).catch((args) => {
            axiosCatch(args, this.props);
        });
    };


    render() {
        return (
            <div className={'center home-news-bar left-right-padding'}>
                <div className={'max-width'}>
                    <DivNews {...this.props} news={this.state.news}/>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('locale')(windowSize(View)));

class DivNews extends Component {
    subject;

    render() {

        const {
            t,
        } = this.props;

        let news = this.props.news;

        if (this.props.windowWidth < 1060 && this.props.windowWidth > 768) {
            news = news.slice(0, 4)
        } else {
            news = news.slice(0, 3)
        }

        if (news.length > 0) {
            return (

                <div className={'div-prenews width'}>
                    <h2>{t('home.news.title')}</h2>
                    <div className={'flex-row flex-wrap home-news'}>

                        {news.map(item =>
                            <Item {...this.props} news={item} key={item.id}/>
                        )}

                    </div>
                </div>
            )
        } else {
            return (
                <div className={'div-prenews width'}>

                </div>
            )
        }
    }

}

class Item extends Component {
    render() {

        const item = this.props.news;
        const {i18n} = this.props;

        let lengNews = 200;

        let preview = item.preview.substring(0, lengNews);
        let preview2 = item.preview.substring(lengNews, 1000);
        let preview3 = preview2.split(" ");
        preview = preview + preview3[0] + "...";

        return (
            <div className={'item'} key={'news' + item.id}>

                <div className={'pre_image pointer'} onClick={() => this.props.history.push("/news/" + item.id)}>
                    <div style={{
                        backgroundImage: `url(/img/news/news${item.id}.jpg)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                    }} className={'image n1'}/>
                </div>
                <div className={'text'}>
                    <b className={'pointer'}
                       onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>{item.subject}</b><br/>
                    <br/>
                    <div className={'pointer'}
                         onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>{

                        preview.split("\\n").map(function (item, idx) {
                            return (
                                <font key={idx}>
                                    {item}
                                    <br/>
                                </font>
                            )
                        })
                    }
                    </div>

                    <span className={'time'}>{item.date}</span>

                </div>

            </div>
        )
    }
}
