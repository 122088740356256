import get from 'lodash/get';

export const numFixed = (length = null) => (cb) => (e) => {
    let value = e.target.value;

    value = value.replace(',', '.');

    let regex;

    switch (length) {
        case null:
            regex = /\d+\.?\d*/g;
            break;

        case 0:
            regex = /\d+/g;
            break;

        default:
            regex = new RegExp(`\\d+\\.?\\d{0,${length}}`, 'g');
    }

    const groups = regex.exec(value);

    value = get(groups, 0, '');

    e.target.value = value;

    cb(e);
};

export default numFixed();
