import ReactDOM from 'react-dom';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import App from './App.js';
import i18n from "./i18n";
import {I18nextProvider as I18nProvider} from 'react-i18next';

ReactDOM.render((
    <BrowserRouter>
        <I18nProvider i18n={i18n}>
            <App/>
        </I18nProvider>
    </BrowserRouter>
), document.getElementById('root'));
