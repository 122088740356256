import React, {Component} from 'react';

import {withRouter} from 'react-router'
import {axiosCatch, axiosinstance} from "../../modules/axios";

import Paginator from '../../modules/paginator';

import '../../css/news.scss';
import '../../css/news-media.scss';
import {translate} from 'react-i18next';

class View extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            news: [],
            paginator: {
                page: 0,
                count: 0,
                limit: 0
            }
        }
    }

    componentDidMount() {
        this.load();
    };

    load = () => {
        const {i18n} = this.props;
        axiosinstance({
            method: 'get',
            url: "/locales/" + i18n.language + "/news_v2.json?" + new Date().getSeconds(),
        })
            .then((response) => {
                let ans = response.data;

                this.setState({
                    news: ans.news.reverse(),
                });

            }).catch((args) => {
            axiosCatch(args, this.props);
        });
    };

    render() {

        const {
            t,
        } = this.props;

        const {i18n} = this.props;

        return (

            <div className={'list-news width bar-padding'}>
                <div className={'max-width width'}>
                    <div className={'content width'}>

                        <span>
                                <h1>{t('locale:list-news.title')}</h1>

                            {this.state.news.map(item =>

                                <div className={'item flex-row flex-draw width'} key={item.id}>
                                    <div className={'pre_image pointer'}
                                         onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>
                                        <div style={{
                                            backgroundImage: `url(/img/news/news${item.id}.jpg)`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center center',
                                            backgroundRepeat: 'no-repeat',
                                        }} className={'image n1'}/>
                                    </div>
                                    <div>
                                <span className={'subject pointer'}
                                      onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>{item.subject}</span>
                                        <span className={'time'}>{item.date}</span>

                                        {
                                            item.preview.split("\n").map(function (item, idx) {
                                                return (
                                                    <font className={'text'} key={idx}>
                                                        {item}
                                                        <br/>
                                                    </font>
                                                )
                                            })
                                        }
                                        <span className="url"
                                              onClick={() => this.props.history.push("/" + i18n.language + "/news/" + item.id)}>{t('locale:list-news.read_more')}</span>
                                    </div>
                                </div>
                            )}

                        </span>

                    </div>
                    <Paginator setPage={this.setPage} paginator={this.state.paginator}/>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('')(View));
