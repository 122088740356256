import React, {Component} from 'react';
import {translate} from 'react-i18next';
//import {axiosCatch, axiosinstance} from "../../modules/axios";
import {withRouter} from 'react-router'
import conf from '../../config.json';

import '../../css/gtfee.scss';
import '../../css/gtfee-media.scss';

class GoldFee extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            fees: {
                storage: conf.annualFee,
                transfer: conf.transferFee
            }
        }
    }

    // componentDidMount() {
    //     this.load();
    // };

    // load = () => {
    //     axiosinstance({
    //         method: 'get',
    //         url: process.env.REACT_APP_BACKEND_URL + "/info",
    //         params: {}
    //     })
    //         .then((response) => {
    //             let ans = response.data;
    //             if (ans.success === true) {
    //                 if (ans.data.fees != null) {
    //                     this.setState({
    //                         fees: ans.data.fees,
    //                     });
    //                 }
    //                 //alert(ans.news);
    //             }
    //             //this.props.onHide();
    //         }).catch((args) => {
    //         axiosCatch(args, this.props);
    //     });
    // };

    render() {
        const {i18n} = this.props;
        const fees = this.state.fees;
        const t = this.props.t;
        return (
            <div className={'gtfee'}>
                <div className="center footer-block bar-padding">
                    <div className="max-width width center">
                        <div className={'center-block'}>
                            <h3>{t('gold_fee.footer-block.title')}</h3>
                            {this.props.source === "features" && (
                                <div className={'flex flex-row flex-center flex-wrap'}>
                                    <div className={' flex flex-column flex-center margin10 flex-center-vertical'}>
                                        <span
                                            className={'spacer'}>{fees.storage}% {t('gold_fee.footer-block.annual-storage-fee')}</span>
                                        <span className={'button-solutions pointer'}
                                              onClick={() => this.props.history.push("/" + i18n.language + "/market")}>{t('gold_fee.footer-block.button-go-to-marketplace')}</span>
                                    </div>
                                    <div className={' flex flex-column flex-center margin10 flex-center-vertical'}>
                                        <span
                                            className={'spacer'}>{fees.transfer}% {t('gold_fee.footer-block.transfer-fee')}</span>
                                        <span className={'button-solutions2 pointer'}
                                              onClick={() => this.props.history.push("/" + i18n.language + "/solutions")}>{t('gold_fee.footer-block.button-learn-more-solutions')}</span>
                                    </div>
                                </div>
                            )}
                            {this.props.source === "solutions" && (
                                <div className={'flex flex-row flex-center flex-wrap'}>
                                    <div className={'flex flex-column flex-center margin10 flex-center-vertical '}>
                                        <span
                                            className={'spacer'}>{fees.storage}% {t('gold_fee.footer-block.annual-storage-fee')}</span>
                                        <span className={'button-solutions pointer'}
                                              onClick={() => this.props.history.push("/" + i18n.language + "/market")}>{t('gold_fee.footer-block.button-go-to-marketplace')}</span>
                                    </div>
                                    <div className={' flex div-buttons flex-column margin10 flex-center-vertical'}>
                                        <span
                                            className={'spacer'}>{fees.transfer}% {t('gold_fee.footer-block.transfer-fee')}</span>
                                        <span className={'button-solutions2 pointer'}
                                              onClick={() => this.props.history.push("/" + i18n.language + "/features")}>{t('gold_fee.footer-block.button-learn-more-features')}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('locale')(GoldFee));

