import React, {Component} from 'react';
//import Details from "./RealTimeDetails.js";
import MainBlock from "./Main.js";
import Advantages from "./Advantages.js";
import Strategy from "./Strategy.js";
import News from "./News.js";
import Purity from "./Purity.js";
import Subsribe from "./Subsribe.js";
import Video from "./Video.js";
import Advisors from "./Advisors.js";


import '../../css/home.scss';
import '../../css/home-media.scss';

class View extends Component {
    render() {
        return (
            <div>
                <MainBlock/>
                {/*<Details/>*/}

                <Purity/>
                <Video/>
                <Advantages/>
                <div style={{height:'100px'}}/>
                <Strategy/>
                <News/>
                <Advisors/>
                <Subsribe/>
                {/*<Details/>*/}
            </div>
        )
    }
}

export default View;

