import React, {Component} from 'react';
import {translate} from 'react-i18next';
import ReactEcharts from 'echarts-for-react';
import dateFormat from 'dateformat';
import {axiosCatch, axiosinstance} from "../../modules/axios";
import Loading from './../../modules/loading'


class View extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading_error: false,
            loading: false,
            cur: 'btc',
            title: "",
            cat_data: [],
            val_data: [],
            min: 0,
            max: 0,
            interval: 1
        }
    }

    componentDidMount() {
        this.load(this.state.cur);
    };

    load = (cur) => {

        this.setState({
            loading: true,
            title: "Spot Gold Price per Gram (" + cur.toUpperCase() + ")",
        }, () => {

            axiosinstance({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + "/graph/" + cur,
                params: {}
            })
                .then((response) => {
                    let ans = response.data;
                    if (ans.success === true) {

                        let round = 0;
                        let round2 = 0;
                        let pro = 0;
                        let interval = 0;

                        if (cur === 'usd') {
                            round = 2;
                            round2 = 0;
                            interval = 1;
                            pro = 2;
                        }

                        if (cur === 'btc') {
                            round = 8;
                            round2 = 4;
                            interval = 0.001
                            pro = 20;
                        }

                        if (cur === 'eth') {
                            round = 6;
                            round2 = 2;
                            interval = 0.05;
                            pro = 20;
                        }

                        let categoryData = [];
                        let valueData = [];

                        let data = ans.data.month;
                        if (data != null) {
                            let min = 1000;
                            let max = 0;

                            for (let i = 0; i < data.length; i++) {
                                if (data[i].value < min) {
                                    min = data[i].value
                                }
                                if (data[i].value > max) {
                                    max = data[i].value
                                }
                                let date = new Date(1000 * data[i].time);
                                categoryData.push(dateFormat(date, "dd-mm-yyyy"));
                                valueData.push(data[i].value.toFixed(round));
                            }
                            this.setState({
                                loading: false,
                                cat_data: categoryData,
                                val_data: valueData,
                                min: (min - (min / 100 * pro)).toFixed(round2),
                                max: (max + (max / 100 * pro)).toFixed(round2),
                                interval: interval,
                            })
                        } else {
                            this.setState({
                                loading: false,
                            })
                        }

                    }
                }).catch((args) => {
                console.log("error");
                this.setState({
                    loading: false,
                    loading_error: true,
                });

                axiosCatch(args, this.props);
            });

        });

    };


    getOption = () => {
        return {
            color: ['#e6be00'],
            title: {
                text: this.state.title,
                left: 44,
                top: 14,
                textStyle: {
                    fontSize: 12
                }
            },
            feature: {
                dataZoom: {
                    yAxisIndex: false
                },
                saveAsImage: {
                    pixelRatio: 2
                }
            },
            tooltip: {
                trigger: 'axis',
                //show: true,   //default true
                showDelay: 0,
                hideDelay: 50,
                transitionDuration: 0,
                backgroundColor: 'rgba(0,0,0,0.7)',
                borderColor: '#434245',
                borderRadius: 3,
                borderWidth: 1,
                padding: 5,
                position: function (p) {
                    return [p[0] + 10, p[1] - 10];
                },
                textStyle: {
                    color: '#ffffff',
                    decoration: 'none',
                    fontSize: 13,
                },
                formatter: function (params) {
                    return 'Date: ' + params[0].name + '<br/>Gold Price: ' + params[0].value
                    // setTimeout(function () {
                    //     callback(ticket, res);
                    // }, 5);
                    // return ' ';
                }
            },
            grid: {
                top: '50px',
                left: '50px',
                right: '30px',
                bottom: '50px',
                containLabel: false
            },
            xAxis: {
                data: this.state.cat_data,
                silent: false,
                splitLine: {
                    show: true
                },
                splitArea: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: '#4f4f4f'
                    }
                }
            },
            yAxis: {
                splitArea: {
                    show: false
                },
                min: this.state.min,
                max: this.state.max,
                axisLine: {
                    lineStyle: {
                        color: '#4f4f4f'
                    }
                },
                interval: this.state.interval,
            },
            dataZoom: {
                show: false,
                start: 0,
                end: 100
            },
            series: [{
                type: 'line',
                symbolSize: 0,
                data: this.state.val_data,
                // Set `large` for large data amount

            }]
        };
    };

    render() {

        const {
            t,
        } = this.props;

        const loading = this.state.loading;

        return (

            <div className={'graph'}>
                <ReactEcharts
                    option={this.getOption()}
                    className='react_for_echarts graph-border'
                />

                <div className={'load flex-center-vertical width'}>
                    <div className={'absolute-load'}>
                        {loading ? (
                            <Loading size={'medium'} color={'dark'}/>
                        ) : (
                            <div/>
                        )}
                    </div>

                </div>

                <div className={'graph-button-area'}>

                    <div className={'graph-button'} onClick={() => this.load('btc')}>{t('market.graph.button-btc')}</div>
                    <div className={'graph-button'} onClick={() => this.load('eth')}>{t('market.graph.button-eth')}</div>
                    <div className={'graph-button'} onClick={() => this.load('usd')}>{t('market.graph.button-usd')}</div>

                </div>
            </div>

        )
    }
}

export default translate('locale')(View);
