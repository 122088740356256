import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import Cache from 'i18next-localstorage-cache';

i18n
    .use(XHR)
    .use(Cache)
    .use(LanguageDetector)
    .init({
        cache: {
            enabled: false
        },
        whitelist: [
            'en', 'es', 'ru', 'tr', 'id', 'de', 'zh', 'ko', 'fr', 'hr', 'fil', 'ja', 'pl', 'ro'
        ],
        fallbackLng: 'en',
        load: 'unspecific',
        ns: ['locale'],
        defaultNS: 'news',
        debug: false,
        initImmediate: true,
        react: {
            wait: true,
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        interpolation: {
            escapeValue: true
        }
    }, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        //t('key'); // -> same as i18next.t
    });


export default i18n;
