import React from 'react';
import '../css/copy.scss';

import Tip from '../modules/tip.js';

class CopyExample extends React.Component {

    constructor(props) {
        super(props);
        this.state = {copySuccess: '', tip: 'Copied!', show: false}
    }

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({
            copySuccess: 'Copied!',
            show: true
        }, ()=>{
            this.sleep(1400).then(() => {
                this.setState({
                    show: false,
                })
            });
        });
    };

    hide = () => {
        this.setState({
            show: false
        })
    };

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {
        return (
            <span className={'copy pointer ' + this.props.size} onClick={this.copyToClipboard}>
                 <Tip
                     color={'blue'}
                     text={this.state.tip}
                     show={this.state.show}
                     action={() => this.hide()}/>
                <form style={{opacity: '0', height: 0, width: 0, position: 'absolute', left: '0px'}}>

                     <textarea className={'copyarea'}
                               ref={(textarea) => this.textArea = textarea}
                               value={this.props.value}
                     />

                </form>
            </span>
        );
    }

}

export default CopyExample;
