import React, {Component} from 'react';
import {translate} from 'react-i18next';

import '../../css/terms.scss';

class View extends Component {
    render() {
        const t = this.props.t;
        return (
            <div>
                <div className="center terms-bar bar-padding">
                    <div className="max-width width">
                        <div className={'left'}>
                            <div className={'div-text'}>
                                <h1 dangerouslySetInnerHTML={{__html: t('privacy.main.title')}}/><br/>
                                <div className={'text'}>
                                    <span dangerouslySetInnerHTML={{__html: t('privacy.main.updates')}}/><br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="center terms-bar-text bar-padding">
                    <div className="max-width width">
                        <div className={'left'}>
                            <div className={'div-text'}>
                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block1.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block1.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block2.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block2.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block3.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block3.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block4.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block4.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block5.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block5.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block6.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block6.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block7.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block7.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block8.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block8.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block9.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block9.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block10.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block10.text')}}/>

                                <h3 dangerouslySetInnerHTML={{__html: t('privacy.block11.title')}}/>
                                <div className={'text'} dangerouslySetInnerHTML={{__html: t('privacy.block11.text')}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);

