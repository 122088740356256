import React, {Component} from 'react';

import Main from './Main.js';
import GoldToken from '../GoldFee/GoldFee.js';

class View extends Component {
    render() {
        return (
            <div>
                <Main/>
                <div className={'gtfee'}>
                <GoldToken source={"features"}/>
                </div>
            </div>
        )
    }
}

export default View;

