import React, {Component} from 'react';
import {translate} from 'react-i18next';
import Copy from '../../../modules/copy'

import {withRouter} from "react-router";

class View extends Component {

    tofixed = (val, num) => {
        return val.toFixed(num).replace(/\.?0*$/, '');
    };

    cutTx = (tx) => {
        return tx.substring(0, 56);
    };

    render() {

        const {
            t,
        } = this.props;

        const order = this.props.order;


        return (

            <div className={'flex width i10'}>

                <div className={'tbodyth-payments in'}>{t('market-order.form.payments')}</div>


                {order.payments.length === 0 ? (
                    <div className={'norec'}>
                        {t('market-order.form.no-records-in')}
                    </div>
                ) : (
                    <div className={'tbody-payment market-order-form'}>
                        <span>

                            {order.payments.map(item =>
                                <div className={'tr'}>
                                    <div className={'small flex-row width'}>
                                        <span className={' flex-nowrap'}><b>{t('market-order.form.txid')}:&nbsp;</b></span>
                                        {(item.tx_id && item.tx_id !== "") ? (
                                            <span className={'flex-row width'}><span className={'flex '}>{this.cutTx(item.tx_id)}...</span><span className={''}><Copy value={item.tx_id} size={'small'}/></span></span>
                                        ) : (
                                            <span>{t('market-order.form.no_tx')}</span>
                                        )}
                                    </div>

                                    <div className={'flex-row'}>
                                        <div>
                                            <div className={'small'}>
                                                <b>{t('market-order.form.amount')}:</b> <span className={'up'}> {this.tofixed(item.amount, 8)} {order.currency_from}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={'small'}>
                                                <b>{t('market-order.form.confirmations')}:</b>&nbsp;
                                                {item.confirmations}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'flex-row'}>
                                        <div className={'small right'}>

                                        </div>

                                        <div className={'flex-row small'}>
                                            <div className={'flex-center-vertical'}>
                                                {item.timestamp}
                                            </div>
                                            <div>
                                                                   <span
                                                                       className={'status status_' + item.status}>{t('market-order.order-status-in.' + item.status)}</span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )}

                        </span>
                    </div>
                )}


            </div>
        )
    }
}

export default withRouter(translate('locale')(View));