import React, {Component} from 'react';
import '../css/paginator.scss';

class View extends Component {

    componentWillReceiveProps() {
        this.setState({
            paginator: this.props.paginator
        });
    }

    setPage(page) {
        this.props.setPage(page)
    };

    pagePlus = () => {
        let paginator = {...this.props.paginator};
        paginator.page = paginator.page + 1;
        if (paginator.page > paginator.count) {
            return
        }
        this.props.setPage(paginator.page)
    };

    pageMinus = () => {
        let paginator = {...this.props.paginator};
        paginator.page = paginator.page - 1;
        if (paginator.page < 1) {
            return
        }
        this.props.setPage(paginator.page)
    };

    render() {

        const {paginator} = this.props;

        if (paginator.count > paginator.limit) {

            let items = [];
            for (let i = 0; i < paginator.count / process.env.REACT_APP_PAGINATION; i++) {
                items.push(i + 1);
            }

            return (
                <div className={'paginator flex-row width'}>
                    <div className={'left'} onClick={this.pageMinus}/>
                    {items.map(item =>
                            <span>
                        {paginator.page === item ? (
                            <div className={'page active'}>
                                {item}
                            </div>
                        ) : (
                            <div className={'page'} onClick={() => this.setPage(item)}>
                                {item}
                            </div>
                        )}
                    </span>
                    )}
                    <div className={'right'} onClick={this.pagePlus}/>
                </div>
            )
        } else {
            return (
                null
            )
        }

    }

}

export default (View);
