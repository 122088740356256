import React, {Component} from 'react';
import {translate} from 'react-i18next';
import conf from '../../config.json'

class View extends Component {
    render() {
        const content = this.props.content;
        const t = this.props.t;

        return (
            <div className="center protection-bar" id={content}>
                <div className="max-width width">
                    <div className={'flex-row box '}>
                        <div className={'left div-text'}>
                            <h2>{t('solutions.' + content + '.title')}</h2><br/>
                            <img src={'/img/' + content + '.png'} alt={''} className={'image i2 '}/><br/>
                            <div className={'text'}>
                                {t('solutions.' + content + '.text-1', { 'annualFee' : conf.annualFee})}<br/><br/>
                                {t('solutions.' + content + '.text-2')}<br/><br/>
                                {t('solutions.' + content + '.text-3')}<br/>
                            </div>
                        </div>
                        <div className={'center div-image'}>
                            <div className={'center-vertical'}>
                                <img src={'/img/' + content + '.png'} alt={''} className={'image i1 ' + content}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('locale')(View);

