import React, {Component} from 'react';
import {translate} from 'react-i18next';

import '../../css/home.scss';

class View extends Component {
    render() {
        const {
            t,
        } = this.props;
        return (
            <div className={'flex-center-vertical bar-padding flex-column'}>
                {t('version')}
            </div>
        )
    }
}

export default translate('locale')(View);
