import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import appRoutes from './../routes.js';
import {withRouter} from 'react-router'
import langs from './../i18n/list'

//import i18n from "i18next/index";

class Header extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showLangBlock: false,
            langs: langs,
            langMap: null,
            lang: ""
        }
    }

    detect = () => {
        let {i18n} = this.props;
        let getLang = i18n.language;

        //let getLang = localStorage.getItem('i18nextLng');

        let err = true;
        for (let i = 0; i < this.state.langs.length; i++) {
            let item = this.state.langs[i];
            if (item.id === getLang) {
                err = false;
            }
        }

        if (!err) {
            //i18n.changeLanguage(getLang);
            this.setState({
                lang: getLang
            });

        } else {
            console.log("error detect");
            //i18n.changeLanguage('en');
            this.setState({
                lang: 'en'
            });

        }

    };

    componentDidMount() {
        this.detect();
        let langMap = new Map();
        let langs = this.state.langs;
        for (let i = 0; i < langs.length; i++) {
            langMap.set(langs[i].id, langs[i])
        }
        this.setState({
            showLangBlock: false,
            langMap: langMap
        })
    };

    actionShowLang = () => {
        this.setState({
            showLangBlock: true
        })
    };

    actionHideLang = () => {
        this.setState({
            showLangBlock: false
        })
    };

    render() {
        let {i18n} = this.props;
        return (
            <header>
                <div className={'top-line-header'}>
                    <div className={'max-width width'}>
                        <div className={'flex-row'}>
                            <div className={'footer-top-block-1'}>
                                <div className={'logo pointer'}
                                     onClick={() => this.props.history.push("/" + i18n.language + "/")}/>
                            </div>
                            <LanguageDiv action={this.actionShowLang} {...this.props} {...this.state}/>
                            <LanguageMenu action={this.actionHideLang} {...this.props} {...this.state}
                                          detect={this.detect}/>
                        </div>
                    </div>
                </div>
                <div className={'top-line-header-logo'}>
                    <div className={'max-width padding width'}>
                        <div className={'flex-row'}>
                            <div className={'logo pointer'}
                                 onClick={() => this.props.history.push("/" + i18n.language + "/")}/>
                            <Menu {...this.props}/>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(translate('locale')(Header));

class LanguageMenu extends Component {

    changeLang = (lang) => {
        const {i18n} = this.props;
        //const link = this.props.link;
        i18n.changeLanguage(lang);
        //this.props.detect();
        window.location.href = '/' + lang  + this.props.link;
        //window.location.reload();
    };

    render() {

        let show = this.props.showLangBlock;
        //let first = false;
        if (show) {
            return (
                <div className={'language-block'} onClick={this.props.action} onMouseLeave={this.props.action}>
                    {this.props.langs.map(item =>

                        //<div className={'flex-row pointer item'} onClick={() => this.changeLang(item.id)}>
                            <a className={'flex-row pointer item no-decoration'} href={'/' + item.id  + this.props.link} onClick={() => this.changeLang(item.id)}>

                            <div className={''}>
                                <img className={'flags_ico'} src={'/img/flags/lang_icon_' + item.flag + '.svg'}
                                     alt={''}/>
                            </div>
                            <div className={'language-text'}>{item.name}</div>
                        </a>
                    )}
                </div>
            );
        } else {
            return null
        }
    }
}

class LanguageDiv extends Component {

    render() {
        let show = this.props.showLangBlock;
        let langMap = this.props.langMap;
        if (!show) {
            if (langMap !== null) {
                return (
                    <div className={'language-div'}>
                        <div className={'flex-row pointer'} onClick={this.props.action}>
                            <div className={'flags-spacer'}/>
                            <div>
                                <img className={'flags_ico'}
                                     src={window.location.origin + '/img/flags/lang_icon_' + langMap.get(this.props.lang)['flag'] + '.svg'}
                                     alt={''}/>
                            </div>

                            <div className={'language-text'}>{langMap.get(this.props.lang)['name']}</div>
                        </div>
                    </div>
                );
            } else {
                return null
            }
        } else {
            return null
        }
    }
}

class Menu extends Component {
    render() {
        let {i18n} = this.props;
        const {
            t,
        } = this.props;
        return (
            <div className={'div-menu'}>
                <menu className={''}>
                    {
                        appRoutes.map((prop, key) => {
                            if (prop.redirect || prop.menuhide) {
                            } else {
                                return (
                                    <NavLink key={key} to={"/" + i18n.language + prop.link} activeClassName="active">
                                        {t('header.menu.' + prop.name)}
                                    </NavLink>
                                );
                            }
                            return null;
                        })
                    }

                    <WP {...this.props}/>

                </menu>

            </div>
        )
    }
}


class WP extends Component {

    render() {

        const {
            t,
        } = this.props;

        let {i18n} = this.props;

        let url = "White_paper_en.pdf";

        switch (i18n.language) {
            case "en":
                url = "White_paper_en.pdf";
                break;
            case "hr":
                url = "White_paper_hr.pdf";
                break;
            case "pr":
                url = "White_paper_pr.pdf";
                break;
            case "tr":
                url = "White_paper_tr.pdf";
                break;
            case "de":
                url = "White_paper_de.pdf";
                break;
            case "pl":
                url = "White_paper_pl.pdf";
                break;
            default:
                url = "White_paper_en.pdf";
                break;
        }

        return(
            <a href={'/wp/' + url} target={'new_'}>{t('header.menu.white-paper')}</a>
        )
    }
}
